$flaticon-font: 'flaticon';

@font-face {
  font-family: $flaticon-font;
  src: url('./flaticon.ttf?ca3472121b87f21d4709132fdcb5c5a1') format('truetype'),
    url('./flaticon.woff?ca3472121b87f21d4709132fdcb5c5a1') format('woff'),
    url('./flaticon.woff2?ca3472121b87f21d4709132fdcb5c5a1') format('woff2'),
    url('./flaticon.eot?ca3472121b87f21d4709132fdcb5c5a1#iefix') format('embedded-opentype'),
    url('./flaticon.svg?ca3472121b87f21d4709132fdcb5c5a1#flaticon') format('svg');
}

i[class^='flaticon-']:before,
i[class*=' flaticon-']:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$flaticon-map: (
  'doctor': '\f101',
  'solidarity': '\f102',
  'light-bulb': '\f103',
  'star': '\f104',
  'hospital': '\f105',
);

.flaticon-doctor:before {
  content: map-get($flaticon-map, 'doctor');
}
.flaticon-solidarity:before {
  content: map-get($flaticon-map, 'solidarity');
}
.flaticon-light-bulb:before {
  content: map-get($flaticon-map, 'light-bulb');
}
.flaticon-star:before {
  content: map-get($flaticon-map, 'star');
}
.flaticon-hospital:before {
  content: map-get($flaticon-map, 'hospital');
}
