.c-intro {
  margin: rem($unit * 2) 0;
  font-size: rem(35px);
  line-height: 1.2;
  opacity: 0;
  transform: translateY($unit);
  transition: opacity 0.6s $easing, transform 0.6s $easing;

  &.is-inview {
    opacity: 1;
    transform: none;
    transition-delay: 0.3s;
  }
}
.project {
  position: relative;
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  &__hero {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 237px;
    background-color: #333;
    .project__wrap {
      position: relative;
      & .baseline-info {
        padding-top: 20%;
        padding-bottom: 2%;
      }
    }
    .short-info {
      width: 100%;
      margin: 50px 0;
      @include media-breakpoint-up(xs) {
        width: 100%;
        margin: 50px 0;
      }
      @include media-breakpoint-up(md) {
        width: 340px;
        margin: 50px 20px;
      }
      @include media-breakpoint-up(lg) {
        width: 340px;
        margin: 50px;
      }
      @include media-breakpoint-up(xl) {
        width: 340px;
        margin: 50px;
      }
      &__item {
        display: inline-block;
        width: 50%;
        text-align: center;
        vertical-align: top;
        padding: 30px;
        &:nth-child(1) {
          border-right: 1px solid #979797;
          border-bottom: 1px solid #979797;
        }
        &:nth-child(2) {
          border-bottom: 1px solid #979797;
          border-right: none;
        }
        &:nth-child(3) {
          border-right: 1px solid #979797;
        }
      }
    }
  }
  &__hero-two {
    width: 100%;
    height: 85vh;
    box-sizing: border-box;
    padding-top: 237px;
    background-color: #333;
  }

  &__hero-three {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: #333;
    .project__wrap {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(xs) {
        flex-direction: column;
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }
  }
  &__wrap-second {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    @include media-breakpoint-up(xs) {
      left: 0;
    }
    @include media-breakpoint-up(md) {
      left: 0;
    }
    @include media-breakpoint-up(lg) {
      left: unset;
    }
    @include media-breakpoint-up(xl) {
      left: unset;
    }
    background: rgba($primary, $alpha: 0.5);
    & .hero__heading {
      padding-top: 30px;
      width: auto;
      font-weight: 300;
      font-size: 49px;
      line-height: 56px;
      letter-spacing: 0.2px;
      color: #fcfcf7;
      @include media-breakpoint-up(xs) {
        padding-top: 30px;
      }
      @include media-breakpoint-up(md) {
        padding-top: 30px;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 70px;
      }
      @include media-breakpoint-up(xl) {
        padding-top: 70px;
      }
    }
  }
  &__label {
    margin: auto auto;
    color: #ffffff;
    font-weight: 100;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    padding-top: 15px;
    position: relative;
    z-index: 2;
  }

  .hero__heading {
    position: relative;
    z-index: 2;
  }
}
.short-info {
  z-index: 2;
  width: 100%;
  height: auto;
  margin: auto;
  padding-top: 0px;
  box-sizing: border-box;
  font-size: 0;
  position: relative;

  &__item {
    display: inline-block;
    padding: 15px 0;
    width: 50%;
    &:first-child {
      border-bottom: 1px solid #979797;
      border-right: 1px solid #979797;
    }
    &:nth-child(2) {
      border-bottom: 1px solid #979797;
    }
    &:nth-child(3) {
      border-right: 1px solid #979797;
    }
    @include media-breakpoint-up(xs) {
      width: 50%;
      &:first-child {
        border-bottom: 1px solid #979797;
        border-right: 1px solid #979797;
      }
      &:nth-child(2) {
        border-bottom: 1px solid #979797;
      }
      &:nth-child(3) {
        border-right: 1px solid #979797;
      }
    }
    @include media-breakpoint-up(md) {
      width: 25%;
      &:not(:last-child) {
        border-right: 1px solid #979797;
        border-bottom: unset;
      }
    }
    @include media-breakpoint-up(lg) {
      width: 25%;
      &:not(:last-child) {
        border-right: 1px solid #979797;
        border-bottom: unset;
      }
    }
    text-align: center;
    vertical-align: top;
  }

  &__icon {
    width: 26px;
    height: 26px;
    display: inline-block;
    margin-bottom: 16px;

    img {
      max-width: 100%;
      max-height: 100%;
      opacity: 0.75;
    }
  }
  &__label {
    color: #979797;
    font-weight: normal;
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
  }
  &__value {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
  }
}

.hero {
  &__heading {
    color: #ffffff;
    font-size: 65px;
    font-weight: bold;
    line-height: 75px;
    text-align: center;
    margin: auto auto;
    display: block;
  }
}
