// ==========================================================================
// Tools / Widths
// ==========================================================================

// Optionally, the boilerplate can generate classes to offset items by a
// certain width. Would you like to generate these types of class as well? E.g.:
//
// @example css
//   .u-push-1/3
//   .u-pull-2/4
//   .u-pull-1/5
//   .u-push-2/3
@use 'sass:math';

$widths-offsets: false !default;

// By default, the boilerplate uses fractions-like classes like `<div class="u-1/4">`.
// You can change the `/` to whatever you fancy with this variable.
$fractions-delimiter: \/ !default;

// When using Sass-MQ, this defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
// <div class="u-3/12@mobile">
$breakpoint-delimiter: \@ !default;

//
// Generate a series of width helper classes
//
// @example scss
//     @include widths(12);
//
// @example html
//    <div class="u-7/12">
//
// @example scss
//     @include widths(3 4, -mobile);
//
// @example html
//    <div class="u-2/3-mobile">
//
// @link     https://github.com/inuitcss/inuitcss/commit/6eb574f/utilities/_utilities.widths.scss
// @requires {Function} important
// @requires {Function} $widths-offsets
// @requires {Function} $fractions-delimiter
// @requires {Function} $breakpoint-delimiter
// @param    {List}     $colums     - The columns we want the widths to have.
// @param    {String}   $breakpoint - Optional suffix for responsive widths.
// @output   `width`, `position`, `right`, `left`
//
@mixin widths($columns, $breakpoint: null, $important: true) {
  $important: important($important);

  // Loop through the number of columns for each denominator of our fractions.
  @each $denominator in $columns {
    // Begin creating a numerator for our fraction up until we hit the
    // denominator.
    @for $numerator from 1 through $denominator {
      // Build a class in the format `.u-3/4[@<breakpoint>]`.
      .u-#{$numerator}#{$fractions-delimiter}#{$denominator}#{$breakpoint} {
        width: math.div($numerator, $denominator) * 100% $important;
      }

      @if ($widths-offsets == true) {
        // Build a class in the format `.u-push-1/2[@<breakpoint>]`.
        .u-push-#{$numerator}#{$fractions-delimiter}#{$denominator}#{$breakpoint} {
          position: relative $important;
          right: auto $important;
          left: math.div($numerator, $denominator) * 100% $important;
        }

        // Build a class in the format `.u-pull-5/6[@<breakpoint>]`.
        .u-pull-#{$numerator}#{$fractions-delimiter}#{$denominator}#{$breakpoint} {
          position: relative $important;
          right: math.div($numerator, $denominator) * 100% $important;
          left: auto $important;
        }
      }
    }
  }
}
