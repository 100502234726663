@font-face {
  font-family: "flaticon";
  src: url("./flaticon.ttf?ca3472121b87f21d4709132fdcb5c5a1") format("truetype"), url("./flaticon.woff?ca3472121b87f21d4709132fdcb5c5a1") format("woff"), url("./flaticon.woff2?ca3472121b87f21d4709132fdcb5c5a1") format("woff2"), url("./flaticon.eot?ca3472121b87f21d4709132fdcb5c5a1#iefix") format("embedded-opentype"), url("./flaticon.svg?ca3472121b87f21d4709132fdcb5c5a1#flaticon") format("svg");
}
i[class^=flaticon-]:before,
i[class*=" flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-doctor:before {
  content: "\f101";
}

.flaticon-solidarity:before {
  content: "\f102";
}

.flaticon-light-bulb:before {
  content: "\f103";
}

.flaticon-star:before {
  content: "\f104";
}

.flaticon-hospital:before {
  content: "\f105";
}/*# sourceMappingURL=flaticon.css.map */