.cursor-ball {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 10001;
}
.cursor-ball_main {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  transition: all 0.4s;
  transition: all 0.3s;
  padding: 1rem;
  &.project-nav {
    width: 8rem;
    height: 8rem;
    background: $primary;
  }
  .cursor-text {
    background: white;
    transition: all 0.4s;
    width: 100%;
    font-size: 1.25rem;
    padding: 2rem 0;
    text-align: center;
    display: block;
    height: max-content;
    border-radius: 50%;
    transform-origin: center;
    text-transform: capitalize;
  }
  &.-is-white {
    transition: all 0.3s;
    background-color: white;
  }
  &.c-more {
    z-index: 10000;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    width: 4rem;
    height: 4rem;
    transition: all 0.4s;
    &.c-close:after {
      transform: translateX(-50%) rotate(135deg) scaleX(1);
    }
    &.c-close:before {
      transform: translateX(-50%) rotate(45deg) scaleX(1);
    }
  }
  &:after,
  &:before {
    content: '';
    transition: all 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 24px;
    margin-top: -1px;
    transform: translateX(-50%) rotate(0deg) scaleX(0);
    background: black;
  }
  &:before {
    transform: translateX(-50%) rotate(-90deg) scaleX(0);
  }
  &.c-more:after {
    transform: translateX(-50%) rotate(90deg) scaleX(1);
  }
  &.c-more:before {
    transform: translateX(-50%) rotate(0deg) scaleX(1);
  }

  &.timeline-wrapper {
    transition: all 0.4s;
    @include media-breakpoint-up(xl) {
      width: 5rem;
      height: 5rem;
    }
    @include media-breakpoint-up(xxl) {
      width: 4rem;
      height: 4rem;
    }
    transform-origin: center;
    background-color: $light-3;
    border: none;
    cursor: none !important;

    .cursor-icon {
      width: 5px;
      height: 5px;
      background: $primary;
      margin: 18px auto;
      border-radius: 50%;
      &:after {
        right: -20px;
        top: 27px;
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.9995 19C9.7735 19 9.5465 18.924 9.3595 18.768C8.9355 18.415 8.8785 17.784 9.2315 17.36L13.7075 11.989L9.3925 6.62701C9.0465 6.19701 9.1145 5.56701 9.5445 5.22101C9.9755 4.87501 10.6045 4.94301 10.9515 5.37301L15.7795 11.373C16.0775 11.744 16.0735 12.274 15.7685 12.64L10.7685 18.64C10.5705 18.877 10.2865 19 9.9995 19Z' fill='%23231F20'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='9' y='5' width='8' height='14'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.9995 19C9.7735 19 9.5465 18.924 9.3595 18.768C8.9355 18.415 8.8785 17.784 9.2315 17.36L13.7075 11.989L9.3925 6.62701C9.0465 6.19701 9.1145 5.56701 9.5445 5.22101C9.9755 4.87501 10.6045 4.94301 10.9515 5.37301L15.7795 11.373C16.0775 11.744 16.0735 12.274 15.7685 12.64L10.7685 18.64C10.5705 18.877 10.2865 19 9.9995 19Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Crect width='24' height='24' fill='%230D1C2E'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
      &:before {
        left: -20px;
        top: 27px;
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8286 19C13.5366 19 13.2466 18.873 13.0486 18.627L8.2206 12.627C7.9226 12.256 7.9266 11.726 8.2316 11.36L13.2316 5.36001C13.5846 4.93601 14.2156 4.87901 14.6406 5.23201C15.0646 5.58501 15.1216 6.21601 14.7676 6.64001L10.2926 12.011L14.6076 17.373C14.9536 17.803 14.8856 18.433 14.4546 18.779C14.2706 18.928 14.0486 19 13.8286 19Z' fill='%23231F20'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='8' y='5' width='7' height='14'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8286 19C13.5366 19 13.2466 18.873 13.0486 18.627L8.2206 12.627C7.9226 12.256 7.9266 11.726 8.2316 11.36L13.2316 5.36001C13.5846 4.93601 14.2156 4.87901 14.6406 5.23201C15.0646 5.58501 15.1216 6.21601 14.7676 6.64001L10.2926 12.011L14.6076 17.373C14.9536 17.803 14.8856 18.433 14.4546 18.779C14.2706 18.928 14.0486 19 13.8286 19Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Crect width='24' height='24' fill='%230D1C2E'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
    p {
      width: fit-content;
      margin: 26px auto;
      color: white;
      letter-spacing: 2px;
      font-weight: 500;
    }
  }
  &.team-slider {
    transition: all 0.4s;
    @include media-breakpoint-up(xl) {
      width: 5rem;
      height: 5rem;
    }
    @include media-breakpoint-up(xxl) {
      width: 4rem;
      height: 4rem;
    }
    transform-origin: center;
    background-color: rgba(187, 187, 187, 0.9);
    border: 1px solid rgba(187, 187, 187, 0.1);
    cursor: none !important;

    .cursor-icon {
      width: 5px;
      height: 5px;
      background: #545454;
      margin: 20px auto;
      border-radius: 50%;
      &:after {
        right: -20px;
        top: 27px;
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.9995 19C9.7735 19 9.5465 18.924 9.3595 18.768C8.9355 18.415 8.8785 17.784 9.2315 17.36L13.7075 11.989L9.3925 6.62701C9.0465 6.19701 9.1145 5.56701 9.5445 5.22101C9.9755 4.87501 10.6045 4.94301 10.9515 5.37301L15.7795 11.373C16.0775 11.744 16.0735 12.274 15.7685 12.64L10.7685 18.64C10.5705 18.877 10.2865 19 9.9995 19' fill='%23231F20'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='9' y='5' width='8' height='14'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.9995 19C9.7735 19 9.5465 18.924 9.3595 18.768C8.9355 18.415 8.8785 17.784 9.2315 17.36L13.7075 11.989L9.3925 6.62701C9.0465 6.19701 9.1145 5.56701 9.5445 5.22101C9.9755 4.87501 10.6045 4.94301 10.9515 5.37301L15.7795 11.373C16.0775 11.744 16.0735 12.274 15.7685 12.64L10.7685 18.64C10.5705 18.877 10.2865 19 9.9995 19' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
      &:before {
        left: -20px;
        top: 27px;
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8287 19C13.5367 19 13.2467 18.873 13.0487 18.627L8.22066 12.627C7.92266 12.256 7.92666 11.726 8.23166 11.36L13.2317 5.35998C13.5847 4.93598 14.2157 4.87898 14.6407 5.23198C15.0647 5.58498 15.1217 6.21598 14.7677 6.63998L10.2927 12.011L14.6077 17.373C14.9537 17.803 14.8857 18.433 14.4547 18.779C14.2707 18.928 14.0487 19 13.8287 19' fill='%23AEAEAE'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='8' y='5' width='7' height='14'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8287 19C13.5367 19 13.2467 18.873 13.0487 18.627L8.22066 12.627C7.92266 12.256 7.92666 11.726 8.23166 11.36L13.2317 5.35998C13.5847 4.93598 14.2157 4.87898 14.6407 5.23198C15.0647 5.58498 15.1217 6.21598 14.7677 6.63998L10.2927 12.011L14.6077 17.373C14.9537 17.803 14.8857 18.433 14.4547 18.779C14.2707 18.928 14.0487 19 13.8287 19' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Crect width='24' height='24' fill='white'/%3E%3Crect width='24' height='24' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
    p {
      width: fit-content;
      margin: 26px auto;
      color: white;
      letter-spacing: 2px;
      font-weight: 500;
    }
  }
}

//for creating the plus sign
.cursor-ball_main .plus-button__line {
  background: white;
  transform: rotate(90deg);
  transition: all 0.5s;
}

.cursor-ball_main.tab-toggle .plus-button__line {
  transform: rotate(0deg);
  transition: all 0.5s;
}

//for creating the minus sign
.cursor-ball_main .plus-button {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.cursor-ball_main.tab-toggle .plus-button {
  transform: rotate(0deg);
  transition: all 0.5s;
}
//for creating the plus sign after tab is in active state
.cursor-ball_main.tab-toggle.-tab-active .plus-button__line {
  transform: rotate(90deg);
  transition: all 0.5s;
}
