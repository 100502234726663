// ==========================================================================
// Generic
// ==========================================================================

html {
  box-sizing: border-box;
}

//
// Add the correct display in IE 10-.
// 1. Add the correct display in IE.
//
template, /* [1] */
[hidden] {
  display: none;
}

*,
:before,
:after {
  box-sizing: inherit;
}

address {
  font-style: inherit;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

b,
strong {
  font-weight: $bold;
}

a {
  text-decoration: none;

  svg {
    pointer-events: none;
  }
}

// Resets
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

p,
figure {
  margin: 0;
  padding: 0;
}

/**
 * 1. Single taps should be dispatched immediately on clickable elements
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* [1] */
  touch-action: manipulation;
}

[hreflang] > abbr[title] {
  text-decoration: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid $black;
}
