.nsd-service__main {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  height: 100%;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    max-width: 17rem;
  }
  @include media-breakpoint-up(lg) {
    max-width: rem(370px);
    margin: 0.5rem;
  }
  background: #fff;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  &:hover {
    background-color: $primary;
    color: #fff;
    .nsd-service__link {
      color: white;
    }
  }
  .nsd-service__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 0px rem(40px) rem(70px);
    width: 100%;
    .nsd-service__link {
      line-height: 2;
      font-family: $lora-font;
    }
    .nsd-service__heading {
      font-size: 2rem;
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }
    }
  }
  .nsd-service__hero-hover {
    font-size: rem(200px);
    display: flex;
    position: relative;
    padding-top: 40px;
    padding-bottom: 70px;
    overflow: hidden;
    white-space: nowrap;
    color: transparent;
    .nsd-service__heading {
      padding-bottom: 20px;
    }
  }
}
