// ==========================================================================
// Objects / Buttons
// ==========================================================================
.o-button {
  position: relative;
  z-index: 1;
  color: $black;
  padding: rem(15px) rem(25px);
  font-size: rem(24px);
  background-color: #ffda00;
  transition: color 0.3s $easing;
  width: 100%;
  text-align: left;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    background-color: $black;
    transition: transform 0.3s $easing;
  }

  @include u-hocus {
    color: wheat;

    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.o-button_icon {
  display: inline-block;
  vertical-align: middle;
  width: rem(55px);
  height: rem(55px);
  margin-right: rem($unit * 0.25);

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.o-button_arrow {
  position: absolute;
  top: 50%;
  right: rem(35px);
  transform: translateY(-50%);
}
.c-slider_button {
  padding: 1.5rem;
  background: #e1e0e0;
}

.button {
  display: flex;
  width: max-content;
  align-items: center;
  margin: 1rem 0 0;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: relative;

    margin: 0 0 0 4.167vw;

    width: 11.111vw;
    height: 11.111vw;

    overflow: hidden;

    pointer-events: none;
    &.-is-dark {
      .button__circle {
        background-color: $accent-1;
      }
      .button__svg {
        stroke: white;
        fill: white;
      }
    }
  }

  &__circle {
    position: absolute;

    width: 11.111vw;
    height: 11.111vw;

    overflow: visible;

    background-color: transparent;
    border: 1px solid $accent-2;

    border-radius: 50%;
  }
  &__svg {
    display: block;

    position: absolute;

    width: 4.278vw;
    height: 1.092vw;

    fill: none;
    stroke: $accent-3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 0.5px;
  }

  &__title--secondary {
    display: none;
  }

  @include media-breakpoint-up(md) {
    height: 3rem;

    &__icon {
      margin: 0 0 0 1rem;

      width: 3rem;
      height: 3rem;
    }

    &__circle {
      width: 3rem;
      height: 3rem;
    }

    &__svg {
      width: 1rem;
      height: auto;
    }

    &__svg--clone {
      transform-origin: left center;
      transform: translateX(-3rem);
    }

    &__title-mask {
      position: relative;
      padding: 0.347vw 0;

      overflow: hidden;
    }

    &__title {
      display: block;

      transform: rotate(0deg);

      transform-origin: right center;

      &--secondary {
        display: block;

        position: absolute;
        left: 0;
        bottom: -3rem;

        transform: rotate(15deg);
        transform-origin: left center;
      }
    }
  }

  @media (hover: hover) {
    &__icon > &__svg--clone {
      transition: transform 0.3s cubic-bezier(0.34, 0, 0.18, 1);
    }

    &__icon,
    &__icon > &__svg,
    &__title-mask > &__title-wrap,
    &__title-mask > &__title-wrap > &__title,
    &__title-mask > &__title-wrap > &__title--secondary {
      will-change: transform;
      transition: transform 0.62s cubic-bezier(0.34, 0, 0.18, 1);
    }

    &:hover > &__title-mask > &__title-wrap {
      transform: translateY(-3rem);
    }

    &:hover > &__title-mask > &__title-wrap > &__title {
      transform: rotate(15deg);
    }

    &:hover > &__title-mask > &__title-wrap > &__title--secondary {
      transform: rotate(0deg);
    }

    &:hover > &__icon > &__svg {
      opacity: 0;

      transform: translateX(3rem);

      transition: 0.3s cubic-bezier(0.34, 0, 0.18, 1);
      transition-property: opacity, transform;

      &--clone {
        opacity: 1;

        transform: translateX(0);

        transition: transform 0.62s cubic-bezier(0.34, 0, 0.18, 1);
      }
    }

    &:hover > &__icon {
      transform: scale(1.2);
    }
  }
}

.button.is-white {
  color: white;
}
.button-slider-open {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 2;

  width: 16.667vw;
  height: 16.667vw;

  background-color: white;

  border-radius: 50%;

  &__icon {
    width: 2.689vw;
    height: 3.228vw;

    fill: $accent-5;
  }

  @media (hover: hover) {
    transform-origin: center center;
    will-change: transform;

    &__icon {
      overflow: visible;
    }

    &__icon-square {
      will-change: transform;
      transition: 400ms cubic-bezier(0.8, 0.16, 0.41, 0.86);
    }

    &:hover &__icon-square:first-of-type {
      // left bottom
      transform: translate(-0.1vw, 0.1vw);
    }

    &:hover &__icon-square:nth-of-type(2) {
      // center bottom
      transform: translate(0, 0.1vw);
    }

    &:hover &__icon-square:nth-of-type(3) {
      // right bottom
      transform: translate(0.1vw, 0.1vw);
    }

    &:hover &__icon-square:nth-of-type(4) {
      // left center
      transform: translate(-0.1vw, 0);
    }

    &:hover &__icon-square:nth-of-type(5) {
      // center center
      transform: translate(0, 0);
    }

    &:hover &__icon-square:nth-of-type(6) {
      // right center
      transform: translate(0.1vw, 0);
    }

    &:hover &__icon-square:nth-of-type(7) {
      // left top
      transform: translate(-0.1vw, -0.1vw);
    }

    &:hover &__icon-square:nth-of-type(8) {
      // center top
      transform: translate(0, -0.1vw);
    }

    &:hover &__icon-square:nth-of-type(9) {
      // right top
      transform: translate(0.1vw, -0.1vw);
    }
  }

  @media (min-width: 768px) {
    width: 4.167vw;
    height: 4.167vw;

    backface-visibility: hidden;

    &__icon {
      width: 0.7392vw;
      height: 0.8877vw;
    }
  }
}
