// ==========================================================================
// Base / Headings
// ==========================================================================

@mixin h {
  margin-top: 0;
  line-height: $line-height;
}

//
// Provide a generic class to apply common heading styles.
//
// @example
//   <p class="u-h"></p>
//
//
.o-h {
  @include h;
  margin-bottom: 0;
  line-height: 1;
  font-weight: $normal;
}

//
// Styles for headings 1 through 6 with classes to provide
// a double stranded heading hierarchy, e.g. we semantically
// need an H2, but we want it to be sized like an H1:
//
// @example
//   <h2 class="o-h1"></h2>
//
//
h1,
.o-h1 {
  @extend .o-h;

  line-height: 0.9;
  text-transform: uppercase;

  @media (max-width: $to-small) {
    font-size: 12vw;
  }

  @media (min-width: $from-small) and (max-width: $to-huge) {
    font-size: $font-size-h1;
  }

  @media (min-width: $from-huge) {
    font-size: 12vw;
  }
}

h2,
.o-h2 {
  @extend .o-h;

  font-size: rem($font-size-h2);
}

h3,
.o-h3 {
  @extend .o-h;

  font-size: rem($font-size-h3);
}

h4,
.o-h4 {
  @extend .o-h;
  line-height: 1.2;

  font-size: rem($font-size-h4);
}

h5,
.o-h5 {
  @extend .o-h;

  font-size: rem($font-size-h5);
}

h6,
.o-h6 {
  @extend .o-h;

  font-size: rem($font-size-h6);
}
.header {
  padding: 20px 0;
  position: relative;
  z-index: 40;
  @include media-breakpoint-down(sm) {
    .o-layout_item {
      width: 50%;
    }
  }
  .header-logo {
    @include media-breakpoint-down(sm) {
      margin: 1rem 0;
    }
  }
  .menu-toggle {
    z-index: 25;
    width: 40px;
    height: 40px;
    background-color: rgb(15, 15, 15);
    cursor: pointer;
    position: relative;
    margin-left: auto;
  }
}
.c-heading.-h2 {
  font-size: 3rem;
  line-height: 1;
}
.hamburger {
  span {
    display: block;
    width: 20px;
    height: 1px;
    margin-bottom: 3px;
    overflow: hidden;
    position: relative;
    &:last-child {
      margin: 0;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 105%;
      height: 100%;
      background-color: rgb(255, 255, 255);
      transform: translateX(-200%);
      transition: transform ease 300ms;
    }
    &:after {
      transform: translateX(0);
    }
    &:nth-child(2):before,
    &:nth-child(2):after {
      transition-delay: 75ms;
    }
    &:last-child:before,
    &:last-child:after {
      transition-delay: 150ms;
    }
    .menu-toggle:hover &:before {
      transform: translateX(0);
    }
    .menu-toggle:hover &:after {
      transform: translateX(200%);
    }
    .menu-toggle.active &:before {
      transform: translateX(100%);
    }
    .menu-toggle.active &:after {
      transform: translateX(200%);
    }
  }
}
.cross {
  span {
    display: block;
    width: 20px;
    height: 1px;
    background-color: rgb(255, 255, 255);
    transform: translateY(50%) rotate(45deg) scaleX(0);
    transition: transform ease 200ms;
    &:last-child {
      transform: translateY(-50%) rotate(-45deg) scaleX(0);
    }
    .menu-toggle.active & {
      transition-delay: 450ms;
      transform: translateY(50%) rotate(45deg) scaleX(1);
    }
    .menu-toggle.active &:last-child {
      transform: translateY(-50%) rotate(-45deg) scaleX(1);
    }
  }
}
.hamburger,
.cross {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nsd-menu-overlay {
  display: block;
  width: 100%;
  height: 150vh;
  top: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 24;
  background: #f3f3f3;

  &__content {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: 100vh;
    align-items: center;
    &::before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    &--part {
      width: 100%;
      overflow: hidden;

      vertical-align: middle;
      &__left {
        .fullscreen-single__item {
          font-family: $karla-font;
          opacity: 0;
          font-size: 2.5rem;
          font-weight: 300;
          line-height: 6.5rem;
          position: relative;
          display: block;
          .sub-menu {
            display: none;
            font-family: $karla-font;
            line-height: 1.3rem;
            &__inner {
              position: relative;
              display: inline-block;
              vertical-align: middle;
              margin: 7px 0;
              font-size: 1rem;
              line-height: 1.3rem;
              font-weight: 400;
              color: $primary;
              &:not(:last-child) {
                padding-right: 15px;
                border-right: 1px solid;
              }
            }
          }
        }
      }
      &__right {
        opacity: 0;
        text-align: center;
        & .nsd-menu__intro {
          text-align: center;
          margin: 20px 20px 60px 20px;
          &-title {
            font-family: $lora-font;
            text-transform: uppercase;
            font-size: 3.2rem;
            @include media-breakpoint-down(md) {
              font-size: 2rem;
            }
            padding-bottom: 1rem;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
