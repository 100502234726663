.c-section {
  position: relative;

  @media (max-width: $to-large) {
    margin-bottom: 15vh;
  }
  &.-margin-0 {
    margin: 0;
  }
  @media (min-width: $from-large) {
    margin-bottom: 30vh;
  }
}

.ptb-120 {
  padding: 120px 0;
}

.c-section_infos {
  position: relative;
  z-index: 0;
  @media (min-width: $from-medium) {
    &.-padding {
      padding: 20vh 20vh 0;
    }
  }

  @media (max-width: $to-medium) {
    margin-bottom: rem($unit * 0.5);
  }
}

.-margin-10 {
  margin-bottom: 15vh;
}

.-margin-20 {
  margin-bottom: 20vh;
}

.c-section_infos_inner {
  > * {
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 0.6s $easing, transform 0.6s $easing;
  }

  &.is-inview {
    > * {
      opacity: 1;
      transform: none;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.06s;
        }
      }
    }
  }
}

.c-sections_infos_text {
  margin-top: rem(20px);
}

.black-overlay-80::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.breadcrumd-section {
  background: #999;
  min-height: 500px;
  display: flex;
  align-items: center;
}

.breadcrumd-section h1 {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 56px;
}

.breadcrumb-nav {
  background-size: cover;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.breadcrumb-nav li {
  color: white;
  font-weight: 800;
}

.breadcrumb-nav li:first-of-type {
  font-weight: 400;
  margin-right: 15px;
}

.breadcrumb-nav li:first-of-type a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
