/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-angle-down:before {
  content: '\f107';
}

.fa-angle-left:before {
  content: '\f104';
}

.fa-angle-right:before {
  content: '\f105';
}

.fa-arrow-left:before {
  content: '\f060';
}

.fa-arrow-right:before {
  content: '\f061';
}

.fa-calendar:before {
  content: '\f133';
}

.fa-clock:before {
  content: '\f017';
}

.fa-envelope:before {
  content: '\f0e0';
}

.fa-file-pdf:before {
  content: '\f1c1';
}

.fa-heart:before {
  content: '\f004';
}

.fa-map-marker:before {
  content: '\f041';
}

.fa-map-marker-alt:before {
  content: '\f3c5';
}

.fa-megaphone:before {
  content: '\f675';
}

.fa-mobile-alt:before {
  content: '\f3cd';
}

.fa-search:before {
  content: '\f002';
}

.fa-star:before {
  content: '\f005';
}

.fa-times:before {
  content: '\f00d';
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('../fonts/fa-light-300.eot');
  src: url('../fonts/fa-light-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fa-light-300.woff2') format('woff2'),
    url('../fonts/fa-light-300.woff') format('woff'),
    url('../fonts/fa-light-300.ttf') format('truetype'),
    url('../fonts/fa-light-300.svg#fontawesome') format('svg');
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('../fonts/fa-regular-400.eot');
  src: url('../fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fa-regular-400.woff2') format('woff2'),
    url('../fonts/fa-regular-400.woff') format('woff'),
    url('../fonts/fa-regular-400.ttf') format('truetype'),
    url('../fonts/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('../fonts/fa-solid-900.eot');
  src: url('../fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fa-solid-900.woff2') format('woff2'),
    url('../fonts/fa-solid-900.woff') format('woff'),
    url('../fonts/fa-solid-900.ttf') format('truetype'),
    url('../fonts/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.srt-breadcrumb::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.4;
  z-index: 0;
}

.srt-breadcrumb .o-container {
  position: relative;
}

.srt-cta {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50% !important;
}

.o-text.srt-faq-text {
  margin-top: 0px;
  margin-bottom: 20px;
}

.timeline-sec {
  position: relative;
}

.timeline-sec::before {
  position: absolute;
  content: '';
  width: 4px;
  height: 100%;
  background: rgb(0 0 0 / 20%);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-sec .c-section_image {
  position: relative;
  overflow: visible;
  box-shadow: 4px 6px 20px 4px rgb(0 0 0 / 4%);
}

.timeline-sec .c-section_image.left::before {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border: 7px solid #0000;
  border-color: #0000 #0000 #0000 #fff;
  top: 24px;
  left: 100%;
}

.timeline-sec .c-section_image.right::before {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border: 7px solid #0000;
  border-color: #0000 #fff #0000 #0000;
  top: 24px;
  right: 100%;
}

.timeline-sec .o-layout.row {
  position: relative;
}

.timeline-sec .timeline-inner-circle {
  width: 50px;
  height: 50px;
  background: transparent;
  position: absolute;
  left: 50%;
  top: 13px;
  transform: translateX(-50%);
  border-radius: 50%;
  border: 3px solid #9f9b9b;
}

.timeline-sec .timeline-inner-circle::before {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #9f9b9b;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.timeline-sec .c-section_image.left {
  margin-right: 50px;
}

.timeline-sec .c-section_image.right {
  margin-left: 50px;
}

.timeline-sec .c-section_image .nsd-news-item .nsd-news-item_content .nsd-item__title h3 {
  margin-bottom: 15px;
}

.timeline-sec .c-section_image .nsd-news-item .nsd-news-item_content .nsd-item__title p {
  color: #6a6a6a;
}

.notification-section {
  background: #d83939;
  color: #fff;
}

.notification-section h4 {
  font-weight: 500;
}

.notification-section .notification-banner h4 a {
  font-weight: 600;
}

.notification-section .notification-banner h4 a:hover {
  color: #fff;
}

@media (max-width: 767px) {
  .timeline-sec .c-section_image.left {
    margin-left: 50px;
    margin-right: 1rem;
  }

  .timeline-sec:before {
    left: 35px;
  }

  .timeline-sec .timeline-inner-circle {
    left: 15px;
  }

  .timeline-sec .c-section_image.left:before {
    left: unset;
    right: 100%;
    border-color: #0000 #fff #0000 #0000;
  }
}

.employee-box {
  padding-top: 30px;
}

@media (max-width: 426px) {
  .employee-box {
    padding-top: 0px;
  }
}
@media (min-width: 1426px) {
  .lg-p-0 {
    padding: 0px;
  }
}
.intro8-section {
  /* filter: brightness(1.55); */
}
.object-top {
  object-position: top;
}
.home-hero-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.1;
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
