h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.c-banner-title {
  font-size: 5rem;
  font-weight: 500;
  max-width: 100%;
  line-height: 1;
  color: $accent-1;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  text-transform: uppercase !important;
  display: block;
  @include media-breakpoint-down(md) {
    font-size: 4rem;
  }
  &.-is-large {
    font-size: 5rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }
  &.-is-small {
    font-size: 3rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }
  &.-is-small-2 {
    font-size: 3.2rem;
    font-weight: 500;
    text-transform: unset !important;
    @include media-breakpoint-down(md) {
      font-size: 3.2rem;
    }

    & .word {
      text-transform: unset !important;
    }
  }
  &.-is-smallest {
    font-size: 2rem;
  }
  &.-is-smallest-2 {
    font-family: $karla-font;
    font-size: 1.5rem;
  }
  &.-is-smallest-3 {
    font-family: $karla-font;
    font-size: 1.2rem;
  }
  .word {
    display: inline-block;
    position: relative;
    line-height: 1;
    // text-transform: capitalize;
    padding: 8px 0;
  }
  &__top {
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    font-family: $karla-font;
    color: $accent-4;
    margin-bottom: 20px;
    &.-right-align {
      width: 80%;
      margin-left: auto;
    }
    &.-center-align {
      width: 100%;
      text-align: center;
    }
  }
}
.c-banner_wrapper {
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.-is-lowercase {
  text-transform: lowercase;
}
.-is-initial {
  text-transform: initial;
}
.-is-uppercase {
  text-transform: lowercase;
}
.-line-height {
  line-height: 1.4;
}
