.c-cta_line {
  margin: 0;
  transform: scaleX(0);
  animation-delay: 0.3s;
  transition: transform 0.6s $easing;
  border-top: 1px solid $accent-2;

  &.-margin {
    margin-bottom: 30px;
  }

  &.is-inview {
    transform: scaleX(1);
  }
}
.c-cta_content {
  margin: rem($unit) 0;
}

.c-cta_content_text {
  > * {
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 0.6s $easing, transform 0.6s $easing;

    &:not(:first-child) {
      margin-top: rem(30px);
    }
  }

  &.is-inview {
    > * {
      opacity: 1;
      transform: none;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.06s;
        }
      }
    }
  }
}

.c-cta_section {
  margin-bottom: rem(180px);
}
.CTA {
  &--light {
    background: $bcg;
    filter: brightness(0.95);
  }
  &__heading {
    font-family: $lora-font;
  }
  &__content {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    padding-top: 16px;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

.cta-section {
  padding: 100px 0;
  margin-bottom: 0 !important;
  min-height: 500px;
  background: url('../images/cta-bg.webp');
  background-size: cover;
  background-position: 70% 50%;

  & .cta-text-holder {
    width: 100%;
  }
}

.srt-cta{
  background: url("../images/srt-cta-bg.png") !important;
  position: relative;
}

.srt-cta::before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.4;
  z-index: 0;
}

.srt-cta .o-layout{
  position: relative;
}

.jarallax {
  position: relative;
  z-index: 0;
}
.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
