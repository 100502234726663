// ==========================================================================
// Utilities / Widths
// ==========================================================================

////
/// @link https://github.com/inuitcss/inuitcss/blob/6eb574f/utilities/_utilities.widths.scss
////

/// Which fractions would you like in your grid system(s)?
/// By default, the boilerplate provides fractions of one whole, halves, thirds,
/// quarters, and fifths, e.g.:
///
/// @example css
///   .u-1/2
///   .u-2/5
///   .u-3/4
///   .u-2/3
@use 'sass:math';

$widths-fractions: 1 2 3 4 5 6 7 !default;

@include widths($widths-fractions);

//////////////////////////////////////////////////////////////////
//these clases are manually added and related to the breakpoints
/////////////////////////////////////////////////////////////////
.u-1\/3\@from-small {
  @media (min-width: $from-small) {
    width: span(math.div(1, 3));
  }
}
.u-2\/3\@from-small {
  @media (min-width: $from-small) {
    width: span(math.div(2, 3));
  }
}
.u-1\/2\@from-small {
  @media (min-width: $from-small) {
    width: span(1 * 0.5);
  }
}
.u-1\/2\@to-tiny {
  @media (max-width: $to-tiny) {
    width: span(1 * 0.5);
  }
}
.u-9\/12\@to-small {
  @media (min-width: $to-small) {
    width: span(math.div(9, 12));
  }
}
.u-3\/12\@to-small {
  @media (min-width: $to-small) {
    width: span(math.div(3, 12));
  }
}
.u-12\/12\@from-small {
  @media (max-width: $to-medium) {
    width: span(math.div(12, 12));
  }
}

.u-1\/3\@from-medium {
  @media (min-width: $from-medium) {
    width: span(math.div(1, 3));
  }
}
.u-1\/4\@from-medium {
  @media (min-width: $from-medium) {
    width: span(1 * 0.25);
  }
}
.u-1\/4\@from-small {
  @media (min-width: $from-small) {
    width: span(1 * 0.25);
  }
}

.u-1\/2\@from-medium {
  @media (min-width: $from-medium) {
    width: span(1 * 0.5);
  }
}
.u-1\/2\@from-large {
  @media (min-width: $from-large) {
    width: span(1 * 0.5);
  }
}

.u-2\/5\@from-medium {
  @media (min-width: $from-medium) {
    width: span(2 * 0.2);
  }
}
.u-1\/5\@from-medium {
  @media (min-width: $from-medium) {
    width: span(1 * 0.2);
  }
}
.u-4\/5\@from-medium {
  @media (min-width: $from-medium) {
    width: span(4 * 0.2);
  }
}

.u-3\/5\@from-medium {
  @media (min-width: $from-medium) {
    width: span(3 * 0.2);
  }
}
.u-4\/10\@from-medium {
  @media (min-width: $from-medium) {
    width: span(4 * 0.1);
  }
}
.u-6\/10\@from-medium {
  @media (min-width: $from-medium) {
    width: span(6 * 0.1);
  }
}
.u-3\/10\@from-medium {
  @media (min-width: $from-small) {
    width: span(3 * 0.1);
  }
}
.u-7\/10\@from-medium {
  @media (min-width: $from-small) {
    width: span(7 * 0.1);
  }
}
