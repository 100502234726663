.c-scrollbar {
  width: 10px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  transform-origin: center right;
  transform: scaleX(1);
  transition: transform 0.2s linear;

  &:hover {
    transform: scaleX(1.3);
    cursor: grab;
  }
}

.c-scrollbar_thumb {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  &:hover {
    cursor: pointer;
  }
}
