// ==========================================================================
// Utilities / Helpers
// ==========================================================================

// Colors
.u-white {
  color: $white;
}

// Text / font
.u-label {
  @include u-label;
}

.u-icon {
  //font-family: $font-lucida;
  font-size: rem(18px);
}

.u-text {
  font-size: rem(14px);
}

// Layout
// ==========================================================================
.u-clearfix {
  @include u-clearfix;
}

// Decorative
// =============================================================================
.u-truncate {
  @include u-truncate;
}

//  Visibility / Display
// ==========================================================================
[hidden][aria-hidden='false'] {
  position: absolute;
  display: inherit;
  clip: rect(0, 0, 0, 0);
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.u-screen-reader-text {
  @include u-accessibly-hidden;
}

@media not print {
  .u-screen-reader-text\@screen {
    @include u-accessibly-hidden;
  }
}

/*
 * Extends the `.screen-reader-text` class to allow the element
 * to be focusable when navigated to via the keyboard.
 *
 * @link https://www.drupal.org/node/897638
 * @todo Define styles when focused.
 */
.u-screen-reader-text.-focusable {
  @include u-accessibly-focusable;
}
.flex {
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}
