.o-image_wrapper {
  position: relative;
  overflow: hidden;
  background-color: $light-3;
}

.o-image {
  opacity: 0.9;
  mix-blend-mode: multiply;
  img {
    width: 100%;
    opacity: 0;
    transform: scale(1.2);
    transition: opacity 1.2s $Power2EaseOut, transform 1.2s $Power2EaseOut;
    &.-is-40 {
      height: 40rem;
      max-height: 40rem;
    }
    &.-is-60 {
      height: 60rem;
      max-height: 60rem;
    }
    &.-is-80 {
      height: 80rem;
    }
  }

  &.is-inview {
    img {
      opacity: 1;
      transform: scale(1);
      transition-delay: 0.6s;
      min-height: 40rem;
      object-fit: cover;
    }
  }
}
.c-overlay-img {
  position: relative;
  .secondary {
    position: absolute;
    top: 20%;
    right: -20%;
    width: fit-content;
  }
}
.c-figure img {
  width: 100%;
  height: auto;
  transition: transform 2.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.o-ratio {
  position: relative;
  display: block;
  overflow: hidden;
  height: 35rem;
  @include media-breakpoint-down(md) {
    height: 25rem;
  }
  &:before {
    display: block;
    width: 100%;
    content: '';
  }
  &_content.-parallax {
    top: -1rem;
    bottom: -1rem;
  }
  &:hover {
    img {
      filter: brightness(0.8);
    }
  }
  img {
    height: -webkit-fill-available;
    object-fit: cover;
    width: 100%;
    transition: all 0.5s;
    filter: brightness(0.6);
  }

  .o-ratio-text {
    z-index: 10;
    color: white;
    font-size: 3rem;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
    line-height: 1.2;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    width: auto;
  }
  .title_xs {
    color: $accent-4;
    position: absolute;
    top: 0;
    margin: 2rem;
  }
  .o-ratio-btn {
    color: white;
    position: absolute;
    bottom: 2rem;
    margin: 0 2rem;
    text-align: left;
    width: auto;
    .button__icon {
      margin: 0;
    }
    .button__title-wrap span {
      color: white;
    }
    .button__circle {
      background-color: transparent;
      border-color: white;
    }
    .button__svg {
      fill: white;
      stroke: white;
    }
  }
}

.gallery .single-card .o-ratio {
  height: unset;

  & img {
    object-fit: contain !important;
  }
}
///////////////////////////
//Process before/process/final-look
//////////////////////////
.owl-carousel .owl-stage {
  display: flex;
}

.testimonials-wrapper {
  & .col-md-2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .testimonial-single {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: auto;
    }
  }
}

.nsd-test-img {
  box-shadow: 0 1em 2em -0.9em rgba(#000, 0.7);
  width: 100%;
  max-width: 400px;
  height: 230px;
  position: relative;
  @include media-breakpoint-up(md) {
    min-width: 100%;
    height: 100%;
  }
  .nsd-test-img--tabs {
    display: flex;
    position: absolute;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
    background: transparent;

    .nsd-test-img--tab {
      outline: none;
      margin: 5px;
      padding: 5px;
      background: #ffffff82;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      width: 100%;
      flex: 1;
      border: none;
      z-index: 99;
      cursor: pointer;
      transition: background 0.5s ease;
      &:hover {
        background: rgba($light-2, 0.8);
      }

      &:last-child {
        border-right: 0;
      }

      &.nsd-test-img--active {
        background: $light-2;
      }
    }
  }

  .nsd-test-img--item {
    opacity: 0;
    display: none;
    height: 100%;
    border: none;
    background: $light-2;
    min-height: 260px;
    &__img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &__label,
    &__container {
      padding: 0;
      transition: inherit;
    }

    &__label {
      margin-bottom: 1.5rem;
    }

    &.nsd-test-img--active {
      display: block;
      opacity: 1;
      transition: opacity 0.8s ease;

      .nsd-test-img--item__container {
        height: 100%;
        padding: 0;
        overflow: hidden;
      }
    }
  }
}
.o-clip {
  position: relative;
  display: block;
  overflow: hidden;
  height: 35rem;
  &:before {
    display: block;
    width: 100%;
    content: '';
  }
  &__content.-parallax {
    top: -1rem;
    bottom: -1rem;
    &.is-inview {
      img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        filter: brightness(1);
        transition: all 1s;
      }
    }
  }
  &__text {
    z-index: 10;
    font-size: 40px;
    position: absolute;
    top: 2rem;
    right: 2rem;
    text-align: end;
    width: 100%;
  }
  img {
    width: 100%;
    height: fit-content;
    object-fit: contain;
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    filter: brightness(0.5);
    transition: all 1s;
  }
}
////////////////////////////
//portfolio next prev button
///////////////////////////
.previous-project,
.next-project {
  height: 30rem;
  overflow: hidden;
  position: relative;
  @include media-breakpoint-down(md) {
    height: 21rem;
  }
  & img {
    height: 30rem;
    @include media-breakpoint-down(md) {
      height: 21rem;
    }
    object-fit: cover;
    width: 100%;
    filter: brightness(0.3);
    transition: all 0.4s;
    background-position: center;
  }
  &:hover {
    img {
      transform: scale(1.2);
      transition: all 0.4s;
    }
    .title {
      opacity: 1;
      transition: all 0.4s;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    color: white;
    margin: 18rem 5rem 0;
    @include media-breakpoint-down(md) {
      margin: 12rem 5rem 0;
    }
    .title {
      font-family: $lora-font;
      font-size: 3rem;
      opacity: 0.6;
      transition: all 0.4s;
      @include media-breakpoint-down(md) {
        opacity: 0.8;
        font-size: 2rem;
      }
    }

    .date {
      opacity: 0.4;
    }
  }
}
////////////////////////////
//portfolio image fullscreen
///////////////////////////
.c-fullview {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9000;
}
.c-image-figure {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  position: relative;
  height: 100%;
  //min-height: 30rem;
  img {
    height: auto;
  }
  .c-fullview {
    height: 100%;
  }
  & > * {
    object-fit: contain;
    height: auto;
    &.is-20 {
      @include media-breakpoint-up(lg) {
        height: 20rem;
      }
    }
    &.is-30 {
      @include media-breakpoint-up(lg) {
        height: 20rem;
      }
    }
    &.is-40 {
      @include media-breakpoint-up(lg) {
        height: 40rem;
      }
    }
    &.is-42 {
      @include media-breakpoint-up(lg) {
        height: 42rem;
      }
    }
    &.is-45 {
      @include media-breakpoint-up(lg) {
        height: 45rem;
      }
    }
    &.is-47 {
      @include media-breakpoint-up(lg) {
        height: 47rem;
      }
    }
    &.is-50 {
      @include media-breakpoint-up(lg) {
        height: 50rem;
      }
    }
    &.is-52 {
      @include media-breakpoint-up(lg) {
        height: 52rem;
      }
    }
    &.is-55 {
      @include media-breakpoint-up(lg) {
        height: 55rem;
      }
    }
    &.is-57 {
      @include media-breakpoint-up(lg) {
        height: 57rem;
      }
    }
    &.is-60 {
      @include media-breakpoint-up(lg) {
        height: 60rem;
      }
    }
    &.is-64 {
      @include media-breakpoint-up(lg) {
        height: 64rem;
      }
    }
    &.is-65 {
      @include media-breakpoint-up(lg) {
        height: 65rem;
      }
    }
    &.is-70 {
      @include media-breakpoint-up(lg) {
        height: 70rem;
      }
    }
    &.is-80 {
      @include media-breakpoint-up(lg) {
        height: 80rem;
      }
    }
  }
}
.s-image-text + .c-image-figure {
  margin-top: -1.875vw;
}

#lookbook {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 95;
  position: fixed;
}
.o-viewer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: fixed;
  backface-visibility: hidden;
  &.-is-higher {
    z-index: 10000;
  }
  img {
    width: 100%;
    object-fit: fill;
  }
}

.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}
