.bg-cream {
  position: relative;
  background: url('./../images/first-screen.webp');
  background-size: cover;
}

.map-info {
  position: relative;
  top: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nsd-map {
  position: relative;
}

.map-address {
  text-align: left;
  padding-bottom: 25px;
}

.map-phone {
  text-align: left;
  padding-bottom: 25px;
}
.map-email {
  text-align: left;
  padding-bottom: 25px;
}
.map-wrapper {
  width: 100%;
  padding: 120px 0px 120px 50px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.map-title {
  margin-bottom: 2rem;
}
