// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #ffffff;
$black: #272727;
$dark: #121212;
// Specific
// =============================================================================
// Link
$link-color: $black;
$link-focus-color: $black;
$link-hover-color: $white;
// Selection
$selection-text-color: $white;
$selection-background-color: $black;

// Social Colors
// =============================================================================
$facebook-color: #3b5998;
$instagram-color: #e1306c;
$youtube-color: #cd201f;
$twitter-color: #1da1f2;

// Customs
$beige: #faebe3;

$primary: #3b3d44; //dark elf
$primary-dark: #202327;
$accent-dark: #af9175;
$bcg: #efeeee;
$accent-1: #333333; //MacKintosh
$accent-2: #6c737f; //Pompei
$accent-3: #7a7a84; // Tarnished
$accent-4: #a8abb3; //Mischka
$accent-5: #595f70; //Grisalle

$light-1: rgba(250, 236, 229, 0.2);
$light-2: #f2f2f2;
$light-3: #dcdde0;
