// ==========================================================================
// Utilities / States
// ==========================================================================

/**
 * ARIA roles display visual cursor hints
 */
[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

/**
 * Control visibility without affecting flow.
 */

.is-visible {
  visibility: visible !important;
  opacity: 1 !important;
}

.is-invisible {
  visibility: hidden !important;
  opacity: 0 !important;
}

/**
 * Completely remove from the flow and screen readers.
 */

.is-hidden {
  @include u-hidden;
}

@media not print {
  .is-hidden\@screen {
    @include u-hidden;
  }
}

@media print {
  .is-hidden\@print {
    @include u-hidden;
  }
}

.is-hidden\@to-large {
  @media (max-width: $to-large) {
    display: none;
  }
}

.is-hidden\@from-large {
  @media (min-width: $from-large) {
    display: none;
  }
}
.-mobile-reverse {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column-reverse;
  }
}
