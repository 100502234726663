// ==========================================================================
// Generic / Forms
// ==========================================================================

input,
select,
textarea {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: none transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
}

select {
  text-transform: none;

  &::-ms-expand {
    display: none;
  }

  &::-ms-value {
    background: none;
    color: inherit;
  }

  // Remove Firefox :focus dotted outline, breaks color inherit
  // &:-moz-focusring {
  //     color: transparent;
  //     text-shadow: 0 0 0 #000000; // Text :focus color
  // }
}

textarea {
  overflow: auto;
  resize: vertical;
}
.subscribe-wrapper {
  position: relative;
  max-width: rem(500px);
  width: rem(500px);
  @include media-breakpoint-down(sm) {
    max-width: rem(400px);
    width: rem(400px);
  }
  margin: auto;
  margin-top: 2rem;
  .subscribe-input {
    width: 100%;
    font-size: 20px;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
    outline: none;
    font-weight: 300;
    background: center;
    line-height: 1.6em;
    color: $accent-3;
    padding: 5px 0;
    border-bottom: 1px solid;
  }
  .subscribe-input-send {
    position: absolute;
    width: auto;
    right: 0;
    bottom: 5px;
    border: 0;
    background: 0 0;
    padding-right: 0;
    cursor: pointer;
    font-size: 25px;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
    text-transform: capitalize;
    color: $accent-3;
    letter-spacing: 0.05em;
  }
}
