.c-fixed_wrapper {
  position: relative;
  overflow: hidden;
  background-color: $beige;

  @media (min-width: $from-medium) {
    height: 100vh;
  }
  @media (max-width: $to-medium) {
    height: 50vh;
  }
}

.c-fixed_target {
  position: absolute;
  top: -100vh;
  bottom: -100vh;
  right: 0;
  left: 0;
}

.c-fixed {
  position: fixed !important;
  right: 0;
  left: 0;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  top: 0;
  html:not(.has-scroll-smooth) & {
    top: 0;
  }
}
