// ==========================================================================
// Objects / Container
// ==========================================================================

//
// Page-level constraining and wrapping elements.
//
// > In programming languages the word *container* is generally used for structures
// that can contain more than one element.
// > A *wrapper* instead is something that wraps around a single object to provide
// more functionalities and interfaces to it.
// @link http://stackoverflow.com/a/13202141/140357
//

/* stylelint-disable */
@if (type-of($container-width) != number) {
  @error "`#{$container-width}` needs to be a number.";
}
/* stylelint-enable */
.o-section {
  &.-dark {
    background: #101012;
    color: white;
  }
  &.-textured {
  }
}
.o-container {
  margin-right: auto;
  margin-left: auto;
  max-width: rem($container-width + (40px * 2));

  @media (max-width: $to-small) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: $from-small) {
    padding-right: rem(40px);
    padding-left: rem(40px);
  }
}
