.crop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  @include if-edge {
    height: unset;
    width: unset;
    min-height: 100%;
    min-width: 100%;
    &-parent.canvas {
      position: absolute;
    }
  }
  .is-ie & {
    height: unset;
    width: unset;
    min-height: 100%;
    min-width: 100%;
    left: 0 !important;
    margin-left: 0 !important;
    &-parent.canvas {
      position: absolute;
    }
  }

  .right & {
    clip-path: polygon(35% 0, 80% 0, 80% 100%, 35% 100%);
  }
  .left & {
    clip-path: polygon(65% 0, 20% 0, 20% 100%, 65% 100%);
  }
  background-size: cover;
  &-shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 40px transparentize($primary, 0.5);
    .right & {
      right: 20%;
      left: 35%;
    }
    .left & {
      right: 35%;
      left: 20%;
    }
  }
  &-wrapper {
    position: relative;
    perspective: 58rem;
    @include media-breakpoint-down(xl) {
      perspective: 50rem;
    }
    @include media-breakpoint-down(md) {
      perspective: 55vh;
    }
  }
  &-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    right: 0;
    z-index: 1;
    transform-style: preserve-3d;
    height: 58rem;
    @include media-breakpoint-down(xl) {
      height: 50rem;
    }
    @include media-breakpoint-down(lg) {
      height: 55vh;
    }
    transform: translate3d(0px, 72px, -75px);
    transition: all 1s;
    &.is-inview {
      transform: translate3d(0px, 20px, 10px);
    }
    .right & {
      left: 0;
      right: -15px;
    }
    .left & {
      left: -15px;
      right: 0;
    }
  }
  &-parent {
    height: 58rem;
    @include media-breakpoint-down(xl) {
      height: 50rem;
    }
    @include media-breakpoint-down(lg) {
      height: 55vh;
    }
  }
}
.block__img-cover {
  @include image-cover;
  float: right;
}
