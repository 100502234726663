// ==========================================================================
// Main
// ==========================================================================

// Settings
// ==========================================================================
@import 'settings/base';
@import 'settings/config.colors';
@import 'settings/config';

// ==========================================================================
// Tools
// ==========================================================================
@import 'tools/functions';
@import 'tools/mixins';
@import 'tools/layout';
@import 'tools/widths';

// Generic
// ==========================================================================
//@import "node_modules/normalize.css/normalize";
@import 'generic/generic';
@import 'generic/media';
@import 'generic/form';
@import 'generic/button';

// Elements
// ==========================================================================
@import 'elements/fonts';
@import 'elements/page';
@import 'elements/footer';
@import 'elements/headings';

// Objects
// ==========================================================================
@import 'objects/container';
@import 'objects/ratio';
@import 'objects/layout';
@import 'objects/form';
@import 'objects/button';
@import 'objects/scroll';
@import 'objects/image';
@import 'objects/map';
@import 'objects/grid';
@import 'objects/text';
@import 'objects/cursor';

// Vendors
// ==========================================================================
//@import "/src/../src/styles/_base.scss";

// Components
// ==========================================================================
@import 'components/portfolio';
@import 'components/title';
@import 'components/crop';
@import 'components/fixed';
@import 'components/intro';
@import 'components/summary';
@import 'components/services';
@import 'components/section';
@import 'components/cta';
@import 'components/badge';
@import 'components/scrollbar';
@import 'components/sliders';
@import 'components/news';
@import 'components/contact';

// Templates
// ==========================================================================
// @import "templates/template";

// Utilities
// ==========================================================================
@import 'utilities/ratio';
@import 'utilities/widths';
@import 'utilities/align';
@import 'utilities/helpers';
@import 'utilities/states';
@import 'utilities/spacing';
@import '../fonts/flaticon.css';

.single-reason {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  margin-bottom: 50px;
}

.single-reason i {
  font-size: 90px;
  line-height: 1;
  color: #fff;
  margin-bottom: 0;
}

.single-reason h3 {
  margin: 5px 0;
  font-weight: 500;
  margin-top: 6px;
  color: #fff;
  text-transform: none;
  text-align: center;
  line-height: 1.3;
}

.single-reason p {
  color: #fff;
}

.single-reason:not(:last-of-type) {
  margin-bottom: 25px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 25px;
}

.bg-girl {
  background: url('../images/girl.jpg');
  background-size: cover;
  background-position: 50%;
}

body:not(.scroll-disabled) .header-absolute .header-logo {
  filter: invert(1);
  transition-delay: 1.2s;
}

.header-logo {
  transition-delay: 0.7s;
}

.header-absolute .menu-toggle {
  border: 1px solid #fff;
}

.h-500 {
  height: 500px;
}

.header-absolute .hamburger span:before,
.header-absolute .hamburger span:after {
  background-color: #fff;
}

.scroll-disabled {
  overflow: hidden;
}

.d-flex\@from-small {
  @media (min-width: $from-small) {
    display: flex;
  }
}

.align-items-center\@from-small {
  @media (min-width: $from-small) {
    align-items: center;
  }
}

.flex-reverse\@to-small {
  @media (max-width: $from-small) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.bp-65 {
  background-position: 65% !important;
}

.c-section {
  margin: 0 !important;
  padding: 110px 0 !important;
}

.c-section.services-section {
  padding-bottom: 110px !important;
}

.c-section.why-section {
  padding-top: 130px !important;
  padding-bottom: 80px !important;
}

.c-section.py-50 {
  padding: 50px 0 !important;
}

.c-section.pt-0 {
  padding-top: 0 !important;
}

.c-section.pb-0 {
  padding-bottom: 0 !important;
}

.c-section.pb-5 {
  padding-bottom: 3rem !important;
}

.c-section.footer-wrapper {
  padding: 80px 0 !important;
}

.brands-section {
  padding: 10px 0 !important;
}

.footer-subscription input,
.footer-subscription button {
  height: 50px;
}

.footer-subscription input {
  padding: 0 12px;
  background: #fff;
}

.map-section {
  padding: 0 !important;
}

.bg-dark {
  background-color: #41444e;
}

.open_sub > .sub-menu {
  display: flex !important;
  flex-direction: column !important;
}

.open_sub.fullscreen-single__item > .sub-menu > li.sub-menu__inner > a > span {
  font-size: 1.5rem !important;
}

.resource-single {
  padding: 12px 24px;
  border: 1px solid #999;
}

.pb-100 {
  padding-bottom: 100px;
}

.hero-section {
  padding: 140px 0;
}

@media (max-width: 1200px) {
  .cta-section {
    background-position: 50% 50% !important;
  }
}

@media (max-width: 991px) {
  .services .single-card {
    margin-bottom: 20px;
  }

  .services .single-card br {
    display: none;
  }

  .single-reason {
    margin-bottom: 20px;
  }

  .c-badge--wrapper img {
    max-width: 250px;
  }

  .contact-1 .contact-form01 {
    margin: 20px 0 0 0 !important;
  }

  .contact-1 .contact1-img {
    top: unset !important;
    height: unset !important;
  }

  .contact-option {
    margin-bottom: 40px !important;
  }

  .contact-1 {
    padding-top: 0 !important;
  }

  .why-image img {
    display: none;
  }

  .c-badge--wrapper {
    margin-top: 0;
    height: unset !important;
  }

  .c-banner-figure {
    margin-bottom: 30px;
  }

  .map-main {
    width: 100% !important;
    position: relative !important;
    height: 400px !important;
    overflow: hidden !important;
  }

  .product-single h5 a {
    font-size: 18px;
  }

  .product-single .product-wrapper {
    min-height: 357px;
  }

  .hero-section {
    padding-top: 80px;
  }

  .hero-section .button {
    margin-top: 2.5rem;
  }
}

@media (max-width: 767px) {
  .cta-section {
    min-height: unset;
  }

  .cta-section h2 .c-banner-title {
    font-size: 20px !important;
  }

  .single-reason h3 {
    margin-top: 8px;
  }

  .c-banner-title {
    font-size: 3rem;
  }

  .breadcrumd-section h1 {
    font-size: 42px;
    line-height: 1.5;
  }
}

@media (max-width: 590px) {
  .intro8-content .title {
    font-size: 32px !important;
  }

  .floating-btn i {
    font-size: 20px !important;
  }

  .product-single {
    margin-bottom: 30px;
  }

  .breadcrumd-section h1 {
    font-size: 32px;
  }
}

/*----------------------------------------*/
/*  05. Header CSS
/*----------------------------------------*/
/* Header Section */
.header-section {
  width: 100%;
  z-index: 1000;
  min-height: 80px;
  position: absolute;
  top: 0;
  left: 0;
}

.header-section .header-inner {
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 80px;
}

.header-wrapper {
  width: 100%;
  background: transparent;
  padding: 10px 0;
}

.header-section.is-sticky.header-sticky-bg-dark .header-inner {
  background-color: #000 !important;
}

.header-section.is-sticky .header-inner {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  -webkit-animation: headerSlideDown 0.95s ease forwards;
  animation: headerSlideDown 0.95s ease forwards;
}

.header-section.header-fluid-two .container {
  max-width: 100%;
}

.header-section.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header-section.header-transparent .header-inner {
  background-color: transparent;
}

.header-section.header-light .header-login a {
  color: #ffffff;
}

.header-section.header-light .header-login a:hover {
  color: #ffffff;
}

@-webkit-keyframes headerSlideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes headerSlideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* 05.06 Header Logo CSS */
.header-logo a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.header-logo a img {
  height: auto;
}

.header-logo a img.light-logo {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 200px;
  height: auto;
}

.home-header img {
  position: relative !important;
  top: unset !important;
  left: unset !important;
  height: unset !important;
  width: 320px !important;
  padding: 10px 0;
}

@media (max-width: 1485px) {
  .home-header img {
    width: 290px !important;
  }
}

.home-header a img.light-logo {
  opacity: 1;
  display: block;
}

.home-header a img.dark-logo {
  opacity: 0;
  display: none;
}

.home-header.is-sticky a img.light-logo {
  filter: brightness(0.1);
}

@media only screen and (max-width: 479px) {
  .header-logo.not-home-header a {
    max-width: 120px;
  }
}

.header-light .header-logo a img.light-logo {
  opacity: 1;
}

.dark-logo {
  width: 230px;
  height: auto;
}

/* 05.02 Main Menu CSS */
.site-main-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

.home-header .site-main-menu {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.is-sticky.home-header:not(.not-home-header) .site-main-menu {
  flex-direction: row;
  justify-content: space-between;
}

.home-header:not(.not-home-header):not(.is-sticky) img {
  width: 440px !important;
}

.site-main-menu .active > a {
  color: #fff !important;
}

.not-home-header .active > a {
  color: #fff !important;
}

.btn-apply {
  padding: 12px 25px !important;
  background: #333;
  color: #fff !important;
  margin-left: 10px;
  border-radius: 5px;
}

.instagram-feed img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.instagram-image {
  position: relative;
}

.instagram-feed .instagram-image::before {
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #111;
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.instagram-feed .col-lg-3:hover .instagram-image::before {
  opacity: 0.35;
}

.instagram-feed .col-lg-3:hover i {
  opacity: 1;
}

.instagram-feed i {
  font-size: 24px !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;
  opacity: 0;

  transition: all 0.5s ease-in-out;
}

.insta-link a {
  text-decoration: none;
}

.insta-link a p {
  display: inline-flex;
  align-items: center;
}

.insta-link svg {
  max-width: 30px;
  margin-right: 8px;
}

@media (max-width: 991px) {
  .single-team__first .single__img {
    object-position: top !important;
  }

  .instagram-feed > div {
    margin-bottom: 40px;
  }
}

.offices-gallery img {
  min-height: 379px;
}

.facilities-gallery img {
  width: 100%;
  height: 205px;
  object-fit: cover;
}

.apply-item {
  display: flex;
  align-items: center;
}

.site-main-menu > ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.site-main-menu > ul > li {
  position: relative;
}

.site-main-menu > ul > li > a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.375;
  display: block;
  padding: 15px 12px;
  color: #f9f9f9;
  @media (max-width: 1485px) {
    font-size: 12px !important;
    padding: 11px 8px !important;
  }
}

.site-main-menu > ul > li > a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 2px;
  width: 0%;
  background: #111;
  transition: width 0.4s ease-in;
}

.site-main-menu > ul > li.active > a::before {
  content: '';
  position: absolute;
  left: 5px;
  bottom: 8px;
  height: 2px;
  width: 90%;
  background: #111;
}

.site-main-menu > ul > li.active.has-children > a::before,
.site-main-menu > ul > li.has-children:hover > a::before {
  width: 90%;
}

.header-section:not(.is-sticky) .site-main-menu > ul > li > a::before {
  background: #fff;
}

.site-main-menu > ul > li:hover > a::before {
  width: 100%;
}

.site-main-menu > ul > li > a:hover {
  text-decoration: none;
}

.home-header .header-mobile-menu-toggle .toggle i::before {
  background: #333;
}

.home-header:not(.is-sticky) .header-mobile-menu-toggle .toggle i::before {
  background: #fff;
}

.home-header .site-main-menu > ul > li > a {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: #fff;
}

.home-header:not(.not-home-header) .site-main-menu > ul > li > a {
  font-size: 20px !important;
}

@media (max-width: 1486px) {
  .home-header:not(.not-home-header) .site-main-menu > ul > li > a {
    font-size: 17px !important;
  }
}

.home-header:not(.is-sticky) .site-main-menu > ul > li > a {
  font-weight: 400 !important;
}

.is-sticky .site-main-menu > ul > li > a {
  color: #333 !important;
}

.site-main-menu > ul > li > a .menu-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pdflinkStyle {
  color: #41444e;
  text-decoration: underline;
}

.site-main-menu > ul > li .menu-toggle {
  display: none;
}

.site-main-menu > ul > li.has-children > a .menu-text::after {
  font-family: 'Font Awesome 5 Pro';
  font-size: 8px;
  font-weight: 400;
  margin-left: 10px;
  content: '\f107';
}

.site-main-menu > ul > li:hover > a {
  color: #fff;
}

.is-sticky .site-main-menu > ul > li:hover > a {
  color: #111;
}

.not-home-header > ul > li:hover > a {
  color: #fff;
}

.not-home-header .site-main-menu > ul > li > a {
  color: #fff;
}

.site-main-menu > ul > li:hover > .sub-menu,
.site-main-menu > ul > li:hover .mega-menu {
  visibility: visible;
  margin-top: 0;
  opacity: 1;
}

.site-main-menu .sub-menu,
.site-main-menu .mega-menu {
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 0;
  visibility: hidden;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 18px 0;
  list-style: none;
  -webkit-transition: all 0.4s ease 0.2s;
  -o-transition: all 0.4s ease 0.2s;
  transition: all 0.4s ease 0.2s;
  opacity: 0;
  border-bottom: 4px solid #333;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
}

.site-main-menu .sub-menu li,
.site-main-menu .mega-menu li {
  position: relative;
}

.site-main-menu .sub-menu li a,
.site-main-menu .mega-menu li a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375;
  display: block;
  padding: 8px 30px;
  white-space: nowrap;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-main-menu .sub-menu li a .menu-text,
.site-main-menu .mega-menu li a .menu-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.site-main-menu .sub-menu li .menu-toggle,
.site-main-menu .mega-menu li .menu-toggle {
  display: none;
}

.site-main-menu .sub-menu li.has-children > a .menu-text::after,
.site-main-menu .mega-menu li.has-children > a .menu-text::after {
  font-family: 'Font Awesome 5 Pro';
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  content: '\f107';
}

.site-main-menu .sub-menu li:hover > a,
.site-main-menu .sub-menu li.active > a,
.site-main-menu .mega-menu li:hover > a,
.site-main-menu .mega-menu li.active > a {
  color: #333;
}

.site-main-menu .sub-menu li:hover > .sub-menu,
.site-main-menu .mega-menu li:hover > .sub-menu {
  visibility: visible;
  margin-top: 0;
  opacity: 1;
}

.site-main-menu .sub-menu .sub-menu,
.site-main-menu .mega-menu .sub-menu {
  top: -18px;
  left: 100%;
}

.site-main-menu .mega-menu {
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 15px 34px;
  list-style: none;
}

.site-main-menu .mega-menu > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 15px;
}

.site-main-menu .mega-menu > li.menu-item-50 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  padding: 0 15px;
}

.site-main-menu .mega-menu > li > ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.site-main-menu .mega-menu > li > ul > li > a {
  font-size: 15px;
  line-height: 1.78;
  font-weight: 500;
  padding: 8px 0px;
}

.site-main-menu .mega-menu > li > ul > li > a .badge {
  background-color: transparent;
  background-image: -webkit-linear-gradient(45deg, #fe378c 0%, #fe5b34 100%);
  background-image: -o-linear-gradient(45deg, #fe378c 0%, #fe5b34 100%);
  background-image: linear-gradient(45deg, #fe378c 0%, #fe5b34 100%);
  display: inline-block;
  border-radius: 2px;
  padding: 4px 8px 3px;
  color: #ffffff;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 0 7px;
}

.site-main-menu .mega-menu > li > ul > li > a .badge.primary {
  background-image: none;
  background-color: #333 !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .site-main-menu.laptop-space > ul > li > a {
    padding-right: 9px;
    padding-left: 9px;
  }
}

.site-main-menu.menu-hover-1 > ul > li > a .menu-text {
  position: relative;
}

.site-main-menu.menu-hover-1 > ul > li > a .menu-text::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  content: '';
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #333;
}

.site-main-menu.menu-hover-1 > ul > li:hover > a .menu-text::before {
  left: 0;
  width: 100%;
}

.header-light .site-main-menu > ul > li > a {
  color: #ffffff;
}

.header-light .site-main-menu > ul > li:hover > a {
  color: #ffffff;
}

.header-light .site-main-menu.menu-hover-1 > ul > li > a .menu-text::before {
  background-color: #ffffff;
}

.header-orange .site-main-menu > ul > li > a {
  color: #3f3a64;
}

.header-orange .site-main-menu > ul > li:hover > a {
  color: #ef6f31;
}

.header-orange .site-main-menu .sub-menu {
  border-bottom: 4px solid #ef6f31;
}

.header-orange .site-main-menu .sub-menu li:hover > a,
.header-orange .site-main-menu .sub-menu li.active > a {
  color: #ef6f31;
}

.header-orange .site-main-menu .mega-menu {
  border-bottom: 4px solid #ef6f31;
}

.header-orange .site-main-menu .mega-menu li:hover > a,
.header-orange .site-main-menu .mega-menu li.active > a {
  color: #ef6f31;
}

.header-orange .site-main-menu.menu-hover-1 > ul > li > a .menu-text::before {
  background-color: #ef6f31;
}

.header-fluid .site-main-menu .mega-menu,
.header-fluid-two .site-main-menu .mega-menu {
  width: 1170px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.header-left .site-main-menu .mega-menu {
  left: 150px;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .header-left .site-main-menu .mega-menu {
    left: 20px;
  }
}

/* 05.03 Mobile Menu Toggle CSS */
.header-mobile-menu-toggle,
.mobile-menu-close {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
}

.header-mobile-menu-toggle .toggle,
.mobile-menu-close .toggle,
.fullscreen-search-close .toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  border: none;
  background-color: transparent;
}

.header-mobile-menu-toggle .toggle i,
.mobile-menu-close .toggle i,
.fullscreen-search-close .toggle i {
  position: relative;
  display: block;
  overflow: hidden;
  width: 24px;
  height: 2px;
}

.header-mobile-menu-toggle .toggle i + i,
.mobile-menu-close .toggle i + i,
.fullscreen-search-close .toggle i + i {
  margin-top: 6px;
}

.header-mobile-menu-toggle .toggle i::before,
.mobile-menu-close .toggle i::before,
.fullscreen-search-close .toggle i::before,
.header-mobile-menu-toggle .toggle i::after,
.mobile-menu-close .toggle i::after,
.fullscreen-search-close .toggle i::after {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #fff;
}

.mobile-menu-close .toggle i::before {
  background-color: #111;
}

.header-mobile-menu-toggle .toggle i.icon-top::before,
.mobile-menu-close .toggle i.icon-top::before,
.fullscreen-search-close .toggle i.icon-top::before,
.header-mobile-menu-toggle .toggle i.icon-bottom::before,
.mobile-menu-close .toggle i.icon-bottom::before,
.fullscreen-search-close .toggle i.icon-bottom::before {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s,
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}

.header-mobile-menu-toggle .toggle i.icon-top::after,
.mobile-menu-close .toggle i.icon-top::after,
.fullscreen-search-close .toggle i.icon-top::after,
.header-mobile-menu-toggle .toggle i.icon-bottom::after,
.mobile-menu-close .toggle i.icon-bottom::after,
.fullscreen-search-close .toggle i.icon-bottom::after {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
}

.header-mobile-menu-toggle .toggle i.icon-middle::before,
.mobile-menu-close .toggle i.icon-middle::before,
.fullscreen-search-close .toggle i.icon-middle::before {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s,
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
}

.header-mobile-menu-toggle .toggle i.icon-middle::after,
.mobile-menu-close .toggle i.icon-middle::after,
.fullscreen-search-close .toggle i.icon-middle::after {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}

.header-mobile-menu-toggle .toggle:hover i::before,
.mobile-menu-close .toggle:hover i::before,
.fullscreen-search-close .toggle:hover i::before,
.header-mobile-menu-toggle .toggle:hover i::after,
.mobile-menu-close .toggle:hover i::after,
.fullscreen-search-close .toggle:hover i::after {
  background-color: #333;
}

.header-mobile-menu-toggle .toggle:hover i.icon-top::before,
.mobile-menu-close .toggle:hover i.icon-top::before,
.fullscreen-search-close .toggle:hover i.icon-top::before,
.header-mobile-menu-toggle .toggle:hover i.icon-bottom::before,
.mobile-menu-close .toggle:hover i.icon-bottom::before,
.fullscreen-search-close .toggle:hover i.icon-bottom::before {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
}

.header-mobile-menu-toggle .toggle:hover i.icon-top::after,
.mobile-menu-close .toggle:hover i.icon-top::after,
.fullscreen-search-close .toggle:hover i.icon-top::after,
.header-mobile-menu-toggle .toggle:hover i.icon-bottom::after,
.mobile-menu-close .toggle:hover i.icon-bottom::after,
.fullscreen-search-close .toggle:hover i.icon-bottom::after {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s,
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
}

.header-mobile-menu-toggle .toggle:hover i.icon-middle::before,
.mobile-menu-close .toggle:hover i.icon-middle::before,
.fullscreen-search-close .toggle:hover i.icon-middle::before {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
}

.header-mobile-menu-toggle .toggle:hover i.icon-middle::after,
.mobile-menu-close .toggle:hover i.icon-middle::after,
.fullscreen-search-close .toggle:hover i.icon-middle::after {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -o-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s,
    -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
}

@media only screen and (max-width: 479px) {
  .header-mobile-menu-toggle,
  .mobile-menu-close,
  .fullscreen-search-close {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.header-light .header-mobile-menu-toggle .toggle i::before,
.header-light .mobile-menu-close .toggle i::before,
.header-light .fullscreen-search-close .toggle i::before,
.header-light .header-mobile-menu-toggle .toggle i::after,
.header-light .mobile-menu-close .toggle i::after,
.header-light .fullscreen-search-close .toggle i::after {
  background-color: #ffffff;
}

.header-light .header-mobile-menu-toggle .toggle:hover i::before,
.header-light .mobile-menu-close .toggle:hover i::before,
.header-light .fullscreen-search-close .toggle:hover i::before,
.header-light .header-mobile-menu-toggle .toggle:hover i::after,
.header-light .mobile-menu-close .toggle:hover i::after,
.header-light .fullscreen-search-close .toggle:hover i::after {
  background-color: #ffffff;
}

/* 05.04 Mobile Menu CSS */
.site-main-mobile-menu {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
}

.site-main-mobile-menu-inner {
  position: relative;
  z-index: 9;
  float: right;
  width: 95vw;
  height: 100%;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

@media only screen and (max-width: 479px) {
  .site-main-mobile-menu-inner {
    width: 100%;
  }
}

.site-main-mobile-menu-inner::before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background-color: #111;
}

#page {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-open {
  overflow: hidden;
}

.mobile-menu-open #page {
  -webkit-transform: translateX(-360px);
  -ms-transform: translateX(-360px);
  transform: translateX(-360px);
}

.mobile-menu-open .site-main-mobile-menu {
  visibility: visible;
  opacity: 1;
}

.mobile-menu-open .site-main-mobile-menu-inner {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.mobile-menu-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 120px;
  padding: 0 15px 0 20px;
  background-color: #ffffff;
}

.mobile-menu-logo a img {
  filter: brightness(0.1);
  width: 70%;
}

.mobile-menu-close,
.fullscreen-search-close {
  padding: 0;
}

.mobile-menu-close .toggle,
.fullscreen-search-close .toggle {
  position: relative;
  width: 30px;
  height: 30px;
}

.mobile-menu-close .toggle i,
.fullscreen-search-close .toggle i {
  position: absolute;
  top: -50%;
  left: 50%;
  width: 17px;
  height: 2px;
  margin: 0 !important;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.mobile-menu-close .toggle i.icon-top,
.fullscreen-search-close .toggle i.icon-top {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.mobile-menu-close .toggle i.icon-bottom,
.fullscreen-search-close .toggle i.icon-bottom {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.mobile-menu-close .toggle:hover i::before,
.fullscreen-search-close .toggle:hover i::before,
.mobile-menu-close .toggle:hover i::after,
.fullscreen-search-close .toggle:hover i::after {
  background-color: #111111;
}

.mobile-menu-content {
  overflow-y: auto;
  height: calc(100% - 80px);
  padding: 20px 30px 100px;
}

.site-mobile-menu > ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.site-mobile-menu > ul > li {
  position: relative !important;
}

.site-mobile-menu > ul > li + li > a {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.site-mobile-menu > ul > li > a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: block;
  padding-top: 19px;
  padding-right: 0;
  padding-bottom: 19px;
  padding-left: 0;
  color: #ffffff;
  border-bottom: 1px solid transparent;
}

.site-mobile-menu > ul > li .menu-toggle {
  position: absolute;
  top: 11px;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 50%;
  background-color: transparent;
}

.site-mobile-menu > ul > li .menu-toggle i {
  font-size: 18px;
  line-height: 1;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.site-mobile-menu > ul > li .menu-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.site-mobile-menu > ul > li.open > a {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.site-mobile-menu > ul > li.open > .menu-toggle {
  background-color: rgba(255, 255, 255, 0.2);
}

.site-mobile-menu > ul > li.open > .menu-toggle i {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.site-mobile-menu .sub-menu,
.site-mobile-menu .mega-menu {
  display: none;
  margin: 14px 0;
  padding-left: 0;
  list-style: none;
}

.site-mobile-menu .sub-menu li,
.site-mobile-menu .mega-menu li {
  position: relative;
  margin-bottom: 22px;
}

.site-mobile-menu .sub-menu li > a,
.site-mobile-menu .mega-menu li > a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 10px;
  padding-right: 0;
  padding-bottom: 10px;
  padding-left: 0;
  color: rgba(255, 255, 255, 0.7);
}

.site-mobile-menu .sub-menu li > a:hover,
.site-mobile-menu .mega-menu li > a:hover {
  color: #ffffff;
}

.site-mobile-menu .sub-menu li .menu-toggle,
.site-mobile-menu .mega-menu li .menu-toggle {
  top: 1px;
}

.site-mobile-menu .sub-menu li.open > a,
.site-mobile-menu .mega-menu li.open > a {
  color: #ffffff;
}

.site-mobile-menu .sub-menu li.open > .menu-toggle,
.site-mobile-menu .mega-menu li.open > .menu-toggle {
  background-color: rgba(255, 255, 255, 0.2);
}

.site-mobile-menu .sub-menu li.open > .menu-toggle i,
.site-mobile-menu .mega-menu li.open > .menu-toggle i {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.site-mobile-menu .sub-menu ul,
.site-mobile-menu .mega-menu ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.site-mobile-menu .sub-menu ul li a .badge,
.site-mobile-menu .mega-menu ul li a .badge {
  background-color: transparent;
  background-image: -webkit-linear-gradient(45deg, #fe378c 0%, #fe5b34 100%);
  background-image: -o-linear-gradient(45deg, #fe378c 0%, #fe5b34 100%);
  background-image: linear-gradient(45deg, #fe378c 0%, #fe5b34 100%);
  display: inline-block;
  border-radius: 2px;
  padding: 4px 8px 3px;
  color: #ffffff;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 0 7px;
}

.site-mobile-menu .sub-menu ul li a .badge.primary,
.site-mobile-menu .mega-menu ul li a .badge.primary {
  background-image: none;
  background-color: #333 !important;
}

.site-mobile-menu .sub-menu .sub-menu,
.site-mobile-menu .mega-menu .sub-menu {
  padding-top: 25px;
  padding-left: 15px;
}

.swiper-container {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-container:hover .swiper-nav-button {
  opacity: 1;
  visibility: visible;
}

.swiper-nav-button i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.swiper-nav-button i::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: '\f104';
}

.swiper-nav-prev i::before {
  content: '\f104';
}

.swiper-button-next i::before {
  content: '\f105';
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  box-sizing: border-box !important;
}

.swiper-wrapper .o-container {
  width: 100% !important;
}

/* swiper default styles */
.swiper-pagination {
  position: static;
  display: block;
  line-height: 1;
  margin-top: 20px;
}

.swiper-pagination.theme-color {
  margin-top: 50px;
}

.swiper-pagination.theme-color .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.swiper-pagination.theme-color .swiper-pagination-bullet:before {
  background: #5c5b6e;
}

.swiper-pagination.theme-color .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #20ad96;
}

.swiper-pagination.theme-color .swiper-pagination-bullet-active:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #20ad96;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 0;
  background: transparent;
  opacity: 1;
  position: relative;
  outline: none;
  margin-right: 20px;
  margin: 0 20px !important;
}

.swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.swiper-pagination .swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: #d8d8d8;
  z-index: 1;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-pagination .swiper-pagination-bullet:hover:before {
  width: 20px;
  height: 20px;
  opacity: 1;
  background: #333333;
}

.swiper-pagination .swiper-pagination-bullet-active:before {
  width: 20px;
  height: 20px;
  opacity: 1;
  background: #333333;
}

.site-wrapper-reveal {
  background-color: #ffffff;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet {
  margin: 14px;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet:hover:before {
  width: 20px;
  height: 20px;
  opacity: 1;
  background: #fff;
}

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet-active::before {
  opacity: 1;
  background: #fff;
  width: 21px;
  height: 21px;
  -webkit-box-shadow: 0 0 9px #ededed;
  box-shadow: 0 0 9px #ededed;
}

.swiper-nav-button {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s,
    opacity 0.1s linear 2s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s,
    opacity 0.1s linear 2s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s,
    opacity 0.1s linear 2s;
  background-image: none;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: #6d70a6;
  border: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin: 0;
  top: 50%;
}

.swiper-nav-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  color: #fff;
  background: #fff;
}

.swiper-nav-button:hover {
  color: #fff;
}

.swiper-nav-button:hover::before {
  color: #fff;
  background: #333333;
}

/* 06.08. Home Eight Intro CSS */
.intro8-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh !important;
  min-height: 700px;
  background-size: cover !important;
  background-position: bottom right;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .intro8-section {
    height: 100vh !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .intro8-section {
    height: 780px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .intro8-section {
    height: 780px !important;
  }
}

@media only screen and (max-width: 767px) {
  .intro8-section {
    height: 700px !important;
  }
}

.intro8-content {
  padding: 60px 0 30px 200px;
}

@media only screen and (max-width: 991px) {
  .intro8-content {
    text-align: center !important;
    padding: 0 !important;
  }
}

.intro8-content > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.intro8-content .label {
  display: inline-block;
  padding: 6px 15px 3px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.65px;
  color: #201c3f;
  background-color: #eab75f;
  margin: 0 0 28px;
}

.intro8-content .title {
  font-family: 'Montserrat', sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 1.2;
  color: #ffffff;
}

@media (max-width: 2020px) {
  .intro8-content .title {
    font-size: 70px;
  }
}
@media (max-width: 1440px) {
  .intro8-content .title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .intro8-content .title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .intro8-content .title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .intro8-content .title {
    font-size: 36px;
  }
}

.intro8-content p {
  font-size: 18px;
  line-height: 1.67;
  color: rgba(255, 255, 255, 0.95);
  margin: 18px 0 0;
}

@media only screen and (max-width: 767px) {
  .intro8-content p {
    font-size: 15px;
  }
}

.btn-dark {
  background-color: #333333;
  color: #fff;
  border-radius: 0;
  padding: 12px 40px;
  margin-top: 40px;
  outline: none;
  transition: 0.3s all;
  display: inline-block;
  text-transform: uppercase;
}

.btn-dark-outline {
  color: #333333;
  border: 2px solid #333;
  border-radius: 0;
  padding: 12px 40px;
  outline: none;
  transition: 0.3s all;
  display: inline-block;
}

.btn-dark-outline .lead {
  font-weight: 400;
}

.btn-dark-outline:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}

.btn-dark:active,
.btn-dark:focus {
  outline: none;
}

.btn-dark:hover {
  background-color: #111;
}

.intro8-slide-pages {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  letter-spacing: 3.2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .intro8-slide-pages {
    display: none;
  }
}

.intro8-slide-pages .current {
  font-size: 60px;
  line-height: 1;
  color: #ffffff;
}

.intro8-slider .swiper-slide {
  background-color: #111;
  background-position: right top;
}

.intro8-slider .first-slide {
  background-position: 90% !important;
}

.swiper-slide-active .intro8-content > * {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.swiper-slide-active .intro8-content > *:nth-child(1) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.swiper-slide-active .intro8-content > *:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.swiper-slide-active .intro8-content > *:nth-child(3) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.swiper-slide-active .intro8-content > *:nth-child(4) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.swiper-slide-active .intro8-content > *:nth-child(5) {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.swiper-slide-active .intro8-content > *:nth-child(6) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

// NEW SLIDER NAVIGATION

.intro8-slider .main-slider-nav {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.25);
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: block;
  text-align: center;
  color: #ffffff;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.intro8-slider .main-slider-nav::after {
  display: none;
}

.intro8-slider .main-slider-nav i {
  font-size: 40px;
  line-height: 1;
  vertical-align: middle;
}

.intro8-slider .main-slider-nav:hover {
  background: rgba(0, 0, 0, 0.45);
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .intro8-slider .main-slider-nav {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }

  .intro8-slider .main-slider-nav i {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .intro8-slider .main-slider-nav {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .intro8-slider .main-slider-nav i {
    font-size: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .intro8-slider .main-slider-nav {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .intro8-slider .main-slider-nav i {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .c-section {
    padding: 60px 0 !important;
  }

  .c-section.services-section {
    padding-bottom: 60px !important;
  }

  .c-section.why-section {
    padding: 60px 0 40px 0 !important;
  }

  .single-reason {
    margin-bottom: 0;
  }

  .c-banner-title.-is-small-2 {
    font-size: 2rem !important;
    line-height: 1.1;
  }

  .intro8-slider .main-slider-nav {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .intro8-slider .main-slider-nav i {
    font-size: 24px;
  }
}

.intro8-slider .home11-slider-prev {
  left: 30px;
}

.intro8-slider .home11-slider-next {
  right: 30px;
}

.intro8-slider .home11-slider-next::after {
  display: none;
}

.intro11-slider:hover .main-slider-nav {
  opacity: 1;
  visibility: visible;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #333 !important;
}

.owl-testimonials .item {
  padding: 40px 35px;
  background: white;
}

.owl-testimonials .item img {
  max-width: 80px;
  border-radius: 50%;
}

.owl-testimonials .item h4 {
  font-size: 20px;
  margin-bottom: 0;
}

p.rating {
  margin-bottom: 15px;
}

p.rating i {
  color: #ffdc17;
}

.gm-style .gm-style-iw-d {
  padding: 0;
  overflow: hidden !important;
  max-height: unset !important;
}

.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
}

.map-box img {
  max-width: 100%;
  width: 100% !important;
  min-height: 205px;
  object-fit: cover;
}

.hospital-header {
  width: 340px;
  position: relative;
}

.hospital-header .hospital-location {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
}

.hospital-location h3 {
  margin-bottom: 10px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 20px !important;
}

.hospital-location h5 {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.75;
  display: block;
}

.hospital-location h5:not(:last-of-type) {
  margin-bottom: 8px;
}

.hospital-location h5 i {
  width: 20px;
}

.hospital-header::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to top,
    rgba(35, 35, 37, 0.85) 0%,
    rgba(35, 35, 37, 0.4) 35%,
    rgba(22, 22, 23, 0) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(35, 35, 37, 0.2);
}

.hospital-body {
  padding: 20px 10px;
}

.gm-style-iw-t button {
  display: none !important;
}

.op-bottom > img {
  object-position: bottom !important;
}

.infoBox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: white;

  display: flex !important;
  align-items: center;
  justify-content: center;
}

.infoBox-close::before {
  line-height: 0;
  padding-top: 2px;
  padding-left: 0px;
}

.gm-style .gm-style-iw-t::after {
  top: -1px !important;
}

.hospital-rating i {
  color: #ffdc17;
}

.hospital-rating a {
  font-size: 14px;
  color: #555;
  text-decoration: none;
}

.floating-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10;
}

.floating-btn a:first-of-type {
  margin-bottom: 10px;
  font-weight: 400;
}

.floating-btn a:first-of-type > i {
  font-size: 24px;
}

.floating-btn a {
  display: flex;
  align-items: center;
  background: white;
  padding: 12px 25px;
  border-radius: 30px;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 10%);
}

.floating-btn i {
  font-size: 32px;
  margin-right: 10px;
  animation: shake 5s ease-in-out infinite;
  perspective: 1000px;
  width: 25px;
  font-weight: 400;
}

.floating-btn i.fa-mobile-alt {
  font-weight: 900;
}

@keyframes shake {
  1%,
  9% {
    transform: translate3d(-1px, 0, 0);
  }

  2%,
  8% {
    transform: translate3d(2px, 0, 0);
  }

  3%,
  5%,
  7% {
    transform: translate3d(-4px, 0, 0);
  }

  4%,
  6% {
    transform: translate3d(4px, 0, 0);
  }
}

.notification-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 6px 0;
  padding: 10px 0px;

  & .left {
    display: flex;
    align-items: center;
    margin-right: 10px;

    & i {
      margin-right: 10px;
    }
  }
  & .right i {
    cursor: pointer;
  }
}

.locations-column i {
  font-size: 28px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.locations-column p {
  display: flex;
  align-items: center;
}

.locations-column .btn {
  margin-top: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.why-section {
  background-size: cover !important;
  background-position: center !important;
}

.why-section .c-banner-title.-is-small-2 {
  color: #fff !important;
}

.text-regular {
  font-size: 16px;
}

.cta-section {
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.cta-section h2 .c-banner-title {
  font-size: 2.2rem !important;
  font-weight: 400;
}

.testimonials-wrapper .owl-stage {
  display: flex;
  align-items: center;
}

.site-mobile-menu > ul > li.has-children > .has-children .sub-menu {
  margin-top: 20px;
}

.services .o-ratio-text {
  font-size: 1.6rem;
}

.services .single-card {
  padding: 0 !important;
}

.providers h3 {
  font-size: 20px;
}

.providers h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}

.providers a:last-of-type {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .cta-section h2 .c-banner-title {
    font-size: 1.6rem !important;
  }
}

.bg-center {
  background-position: center !important;
}

.media-wrapper {
  background-color: #fff;
  padding: 20px 0;
}

.list-style-disc {
  list-style: disc;
}

.double-line-height {
  line-height: 2rem;
}

.giant-quote {
  position: relative;

  &::before {
    content: attr(data-content);
    font-size: 15rem;
    color: hsl(0deg 0% 0% / 0.15);
    position: absolute;
    top: 50%;
    left: 0;
  }
}

.wide-text {
  letter-spacing: 0.1rem;
}

.laser-inset {
  border: solid 1px black;
  border-radius: 3px;
  position: relative;
  height: 450px;
  overflow-y: hidden;

  & img {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    object-fit: contain;
    object-position: center top;
  }

  & div {
    background-color: white;
    position: absolute;
    bottom: 45px;
    left: 45px;
    right: 45px;
    padding: 15px;
  }

  & ul {
    margin-bottom: 1rem;
    list-style: disc;
    list-style-position: inside;
  }
}

img.position-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  down: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 550px) {
  img.position-bg-image {
    object-position: 90% 0;
  }
}

.bg-black {
  background-color: black;
}

.grid-banner {
  background-color: hsl(0deg 0% 0% / 0.1);
  display: grid;
  place-items: center;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 3fr 1fr;
  row-gap: 1rem;

  & .self-start {
    align-self: start;
  }

  & .self-end {
    align-self: end;
  }

  & img {
    grid-row: 1 / span 2;
    width: 40vw;
    margin: 4.5rem 0;
  }

  & .banner-button {
    background-color: white;
    padding: 0.6rem 2rem;
    border-radius: 2rem;
    box-shadow: var(--shadow-elevation-high);
    text-transform: none;
    font-weight: bold;
    display: flex;
    align-items: center;

    & i {
      font-size: 1.5rem;
    }
  }
}

.grid-list-inset {
  width: 100%;
  padding: 0 1.5rem;
  margin: 0 auto;
  display: grid;
  align-items: center;
  height: 50rem;
  gap: 1rem;

  @media (min-width: $from-small) {
    width: 75vw;
  }

  @media (min-width: $from-medium) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3fr 2fr;
    grid-auto-flow: column;
  }

  & .laser-inset {
    height: 100%;
  }

  & .span-2 {
    grid-row: 1 / span 2;
  }

  & ul {
    padding-left: 2rem;
  }
}

:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.16),
    0px 0.7px 0.8px -2.7px hsl(var(--shadow-color) / 0.15);
  --shadow-elevation-medium: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.15),
    -0.1px 0.9px 1px -1.3px hsl(var(--shadow-color) / 0.14),
    -0.2px 3.6px 3.9px -2.7px hsl(var(--shadow-color) / 0.13);
  --shadow-elevation-high: 0px 0.2px 0.2px hsl(var(--shadow-color) / 0.14),
    -0.1px 1.3px 1.4px -0.5px hsl(var(--shadow-color) / 0.13),
    -0.2px 2.7px 3px -1.1px hsl(var(--shadow-color) / 0.13),
    -0.4px 5.4px 5.9px -1.6px hsl(var(--shadow-color) / 0.13),
    -0.7px 10px 11px -2.2px hsl(var(--shadow-color) / 0.12),
    -1.2px 17.6px 19.3px -2.7px hsl(var(--shadow-color) / 0.12);
}

.breadcrumd-section {
  position: relative;
}

.breadcrumd-section::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
}

.breadcrumd-section .o-container {
  position: relative;
}

.breadcrumd-section .family-jarallax-img {
  object-position: 50% 28%;
}
.o-layout_item {
  margin-bottom: 15px !important;
}

.dropdown-section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  gap: 100px;
  align-items: start;

  .image-container {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .dropdown-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .dropdown {
      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #0000001c;
        font-size: 24px;
        font-weight: 600;
        color: #212529;
      }
      button:focus {
        outline: none;
      }

      .dropdown-content {
        padding-top: 20px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        ul {
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 16px;

          li {
            color: #272727bd;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }

      .dropdown-content.active {
        display: block;
        transition: all 0.3s ease-in-out;
        max-height: 200px;
      }
      .last-dropdown-content.active {
        display: block;
        transition: all 0.3s ease-in-out;
        max-height: 400px !important;
      }
    }

    .bropdownSectionBtn {
      border: 2px solid #333;
      color: #333;
      font-size: 18px;
      padding: 18px 42px;
      text-decoration: none;
    }
  }
}

@media (max-width: 991px) {
  .dropdown-section-container {
    grid-template-columns: 1fr;
  }
}

.laser-skin-section {
  h2 {
    text-align: start;
  }

  .contentBoxes {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    width: 100%;
    margin: 32px 0;

    .contentBox {
      display: flex;
      align-items: end;
      position: relative;
      padding: 32px;
      height: 482px;
      transition: all 0.3s ease-in-out;

      .imageContainer,
      .imageContainer2 {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        .gradient-div {
          transition: all 0.3s ease-in-out;
          opacity: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to top right, black, transparent);
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: 0.3s ease-in-out;
        }
      }
      .contentBoxText {
        position: relative;
        z-index: 20;
        transition: all 0.3s ease-in-out;

        h4 {
          font-size: 36px;
          font-weight: 600;
          color: white;
        }

        ul {
          max-height: 0;
          padding-top: 12px;
          list-style: disc;
          overflow: hidden;
          transition: 0.3s ease-in-out;

          li {
            color: white;
            margin-left: 22px;
          }
        }
      }
    }

    .contentBox:hover .contentBoxText ul {
      max-height: 500px;
    }

    .contentBox:hover > .imageContainer2 > .gradient-div {
      opacity: 100;
    }
  }

  @media (max-width: 991px) {
    .contentBoxes {
      grid-template-columns: 1fr;
    }
  }
}

.contentBoxLink {
  font-size: 18px;
  text-transform: uppercase;
  color: #333;
  font-weight: 500;
  padding: 22px 0;
}

.whyChooseUsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 106px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;

    a {
      margin-top: 30px;
    }
  }

  .imageContainer {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 991px) {
  .whyChooseUsSection {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}

.skinTransformationContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 60px;
  row-gap: 30px;
  margin-top: 32px;

  .content1,
  .content2,
  .content3 {
    ul {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      list-style: disc;

      li {
        margin-left: 22px;
        font-size: 18px;
        color: #212529;
        font-weight: 400px;
      }
    }
  }

  .content2 {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

@media (max-width: 991px) {
  .skinTransformationContent {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    .content2 {
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }
}

.mediaContainer {
  .mediaLogos {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      background-color: white;
      padding: 40px;

      p {
        font-weight: 600;
      }

      .img-container {
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .mediaContainer {
    .mediaLogos {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media (max-width: 700px) {
  .mediaContainer {
    .mediaLogos {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (max-width: 450px) {
  .mediaContainer {
    .mediaLogos {
      grid-template-columns: 1fr;
    }
  }
}

.contact-link {
  padding-top: 24px;
  color: white;
  line-height: 1.5;

  a {
    transition: color 0.3s ease-in-out;
    color: rgba(255, 255, 255, 0.658);
    padding: 0 10px;
    text-decoration: underline;
  }
  a:hover {
    color: white;
  }
}
.contact-link2 {
  padding-top: 24px;
  color: #111;
  line-height: 1.5;

  a {
    transition: color 0.3s ease-in-out;
    color: #111111be;
    padding: 0 10px;
    text-decoration: underline;
  }
  a:hover {
    color: #111;
  }
}

.view-menu-link {
  color: white;
  font-size: 20px;
}
.view-menu-link:hover {
  color: rgba(255, 255, 255, 0.588);
  text-decoration: none;
}
.view-menu-link2 {
  color: #111;
  font-size: 20px;
}
.view-menu-link:hover {
  color: #1111119c;
  text-decoration: none;
}
